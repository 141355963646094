import { Component, Input, AfterViewInit, ElementRef, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';

import { ResourceService, IResource } from '~shared/services/resource/resource.service';
import { handleSwaggerError } from '~shared/helpers';
import { each } from 'lodash-es';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'fm-search',
    templateUrl: 'search.component.html'
})
export class FMSearchComponent implements AfterViewInit, OnDestroy, OnChanges {
    @Input() required = false;
    @Input() placeholder = 'Selectează o valoare';
    @Input() type: number = null;
    @Input() value:string = null;
    @Input() minCharachers: number = 3

    @Output('onChange') onChangeEmitter: EventEmitter<number> = new EventEmitter<number>();

    errors = [];
    loading = false;
    setToastr = true;

    constructor(public element: ElementRef, private resourceService: ResourceService) {}    

    ngAfterViewInit() {
        this.getSearchedValue();
    }

    ngOnDestroy() {
        ($(this.element.nativeElement.children[0]) as any).search('clear');
    }

    ngOnChanges(changes: SimpleChanges) {
        const value: SimpleChange = changes.value;
        if (value) {
            if (value.currentValue !== value.previousValue) {
                ($(this.element.nativeElement.children[0]) as any).search('set value', value.currentValue );
            }
        }
    }

    private getSearchedValue() {
        const self = this;
        ($(this.element.nativeElement.children[0]) as any).find('.prompt').on('change keyup copy paste cut', function () {
            if (!this.value) {
               self.onChangeEmitter.emit(null);
            }
        });      
    
        ($(this.element.nativeElement.children[0]) as any).search({
            minCharacters: this.minCharachers,
            filterRemoteData: true,
            fields: {
                remoteValues: 'results',
                title: 'name',
                description: 'value',
                value: 'id'
            },
    
            onSelect: (selection) => {
                this.onChangeEmitter.emit(selection.id);    
            },
    
            // onSearchQuery: (query) => {
            //     this.onChangeEmitter.emit(query);
            // },
    
            apiSettings: {
                responseAsync: (settings, callback) => {                   
                    this.resourceService
                        .getAutocompleteResults(this.type, settings.urlData.query)
                        .subscribe(
                            (response: IResource[]) => {
                                const returnObj = { success: response, results: [] };
                                each(response, (item) => {
                                    returnObj.results.push(item);
                                });
                                callback(returnObj);
                            }, handleSwaggerError.bind(this)
                        );
                },
            },
            interruptRequests: true,
        });
    }
    
}

