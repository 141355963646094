<h4> Confirmă contul </h4>


<div class="ui form">
    <form class="ui large form fadeIn">
        <div class="ui mini error message"></div>
        <div class="ui mini red message" *ngIf="errors.length">
            <ul class="list">
                <li *ngFor="let error of errors" [innerHTML]="error"></li>
            </ul>
        </div>
        <div class="ui stacked">
            <div class="field">
                <div class="ui input">
                    <input type="text" name="securityCode" placeholder="Cod de securitate" [(ngModel)]="model.securityCode" required="required">
                </div>
            </div>
            <button class="ui fluid black submit button login" [ngClass]="{'loading':loading}" (click)="activate()" [disabled]="loading">Salvează</button>
        </div>
    </form>
</div>