<div>
  <div class="ui grid stackable">
    <div class="four wide computer five wide tablet sixteen wide mobile column">
      <h3>Istoric statuturi</h3>
    </div>
    <div class="nine wide computer five wide tablet sixteen wide mobile column"></div>
  </div>

  <div class="ui divider"></div>

  <no-data *ngIf="!(statutHistory?.length > 0) && !loading && !error"></no-data>
  <app-loader *ngIf="loading"></app-loader>
  <invalid-request *ngIf="error" [(refresh)]="refresh"></invalid-request>

  <section [hidden]="loading || !statutHistory?.length">
    <div class="table-container" *ngIf="!error">
      <table class="ui very basic sortable table">
        <thead>
          <tr>
            <th *ngFor="let column of columns" class="sorted">{{column.label}}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of statutHistory">
            <tr [hidden]="loading">
              <td>{{ item.oldStatus.name }}</td>
              <td>{{ item.newStatus.name }}</td>
              <td>{{ item.modificationDate | date: 'yyyy-MM-dd' }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </section>
</div>
