import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment-mini';
import { ValidationRules, YearFormat } from '~shared/helpers';
import { BaseAddComponent } from '~shared/helpers/classes/base-component/base-add-component';
import { DecisionService } from '~shared/services/decision/decision.service';
import { DecisionModel } from '~shared/services/decision/models';

const fields = {
  'nume': { rules: ValidationRules.empty },
  'descriere': { rules: ValidationRules.empty },
  'an': { rules: ValidationRules.empty }
};

@Component({
  selector: 'app-add-decision',
  templateUrl: './add-decision.component.html',
  styleUrls: ['./add-decision.component.scss']
})
export class AddDecisionComponent extends BaseAddComponent<DecisionModel> implements OnInit {

  override redirectUrl = '/manage-decision';
  yearFormat = YearFormat;

  constructor(service: DecisionService, router: Router, route: ActivatedRoute) {
    super(service, DecisionModel, fields, 'decizia', route, router, true);
    this.route.params.subscribe((params) => {
      if (params.artistId) this.model.artistId = Number(params.artistId);
      this.redirectUrl = `/${this.model.artistId}/manage-decision`;
    })
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  yearChange($event){
    this.model.year = moment($event).format('YYYY');
  }
}
