import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/shared/services/base.service';
import { saveAs } from 'file-saver';

@Injectable({ providedIn: 'root' })
class MemberService extends BaseService {

  constructor() {
    super('user');
  }

  changePassword(model: any): Observable<any> {
    return this.http.post<any>(this.url + '/change-password', model)
  }

  sendResetPasswordCode(email): Observable<any> {
    return this.http.post(this.url + "/reset-password-code", { email: email });
  }

  resetPassword(model): Observable<any> {
    return this.http.post(this.url + "/reset-password", model);
  }

  getAvatar(id: number): Observable<any> {
    return this.http.get(this.url + "/" + id + "/blobs");
  }

  saveAvatar(id: number, model): Observable<any> {
    return this.http.post(this.url + "/" + id + "/blob", model);
  }

  deleteAvatar(id: number, avatarId: number): Observable<any> {
    return this.http.delete(this.url + "/" + id + "/blob/" + avatarId);
  }

  resetData(id: number): Observable<any> {
    return this.http.post(this.url + "/reset-data", { memberId: id });
  }
}

export { MemberService };
