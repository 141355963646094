import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { saveAs } from 'file-saver';
import { ExportArtistModel } from './models';

@Injectable({
  providedIn: 'root'
})
export class ArtistService extends BaseService {
  constructor() {
    super('artist');
  }
  downloadFile(model: ExportArtistModel) {
    const headers = { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    let url = `api/report/export-members?`;

    const keys = Object.keys(model).filter(key => model[key] !== null && model[key] !== undefined);
    keys.forEach((key, index) => {
      if (index !== 0) {
        url += '&';
      }
      url += `${key}=${model[key]}`;
    });

    let filters = [model.branch, model.speciality, model.statut, model.isAlive, model.quality]
      .filter(name => name || name == 'Toți')
      .join('_');

    this.http.get(url, { headers, responseType: 'blob' })
      .subscribe((response: Blob) => {
        const today = new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '').replace(/(\d{2})(\d{2})(\d{4})/, '$2$1$3');
        const fileName = `Listă artiști_${filters}_${today}.xlsx`;
        saveAs(response, fileName);
      },
      (error) => {
        console.error('Error downloading file:', error);
      }
    );
  }

  exportArtist(artistId: number, fileName: string): void {
    const headers = { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    let url = `api/report/export-artist?id=${artistId}`;

    this.http.get(url, { headers, responseType: 'blob' }).subscribe(
      (response: Blob) => {
        saveAs(response, fileName);
      },
      (error) => {
        console.error('Error downloading file:', error);
      }
    );
  }

  exportArtistDebt(model: ExportArtistModel) {
    const headers = { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    let url = `api/report/export-receipts?`;

    const keys = Object.keys(model).filter(key => model[key] !== null && model[key] !== undefined);
    keys.forEach((key, index) => {
      if (index !== 0) {
        url += '&';
      }
      url += `${key}=${model[key]}`;
    });

    const filters = [model?.branch, model?.speciality, model?.statut, model?.isAlive, model?.quality].filter(Boolean).join('_');

    this.http.get(url, { headers, responseType: 'blob' }).subscribe(
      (response: Blob) => {
        const today = new Date().toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '').replace(/(\d{2})(\d{2})(\d{4})/, '$2$1$3');
        const fileName = `Listă cotizații_${filters}_${today}.xlsx`
        saveAs(response, fileName);
      },
      (error) => {
        console.error('Error downloading file:', error);
      }
    );
  }
}
