import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '~shared/helpers/classes/base-component/base-component';
import { Column } from '~shared/helpers/classes/pagination/pagination';
import { BranchService } from '~shared/services/branch/branch.service';
import { BranchModel } from '~shared/services/branch/models';

const columns = [
  new Column('name', 'Nume'),
  new Column('code', 'Cod')
];

@Component({
  selector: 'app-manage-branch',
  templateUrl: './manage-branch.component.html',
  styleUrls: ['./manage-branch.component.scss']
})
export class ManageBranchComponent extends BaseComponent<BranchModel> implements OnDestroy {
  override columns = columns;

  constructor(service: BranchService) {
    super(service, 'filiala');
  }

  exportExcel() {
    this.service.downloadFile();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
