<h4> Înregistrează-te </h4>


<div class="ui form">
    <form class="ui large form fadeIn">
        <div class="ui mini error message"></div>
        <div class="ui mini red message" *ngIf="errors.length">
            <ul class="list">
                <li *ngFor="let error of errors" [innerHTML]="error"></li>
            </ul>
        </div>
        <div class="ui stacked">
            <div class="field">
                <div class="ui input">
                    <input type="text" name="firstName" placeholder="Prenume" [(ngModel)]="register.firstName" required="required">
                </div>
            </div>
            <div class="field">
                <div class="ui input">
                    <input type="text" name="lastName" placeholder="Nume" [(ngModel)]="register.lastName" required="required">
                </div>
            </div>
            <div class="field">
                <div class="ui input">
                    <input type="text" name="email" placeholder="Email" [(ngModel)]="register.email" required="required">
                </div>
            </div>
            <div class="field">
                <div class="ui input">
                    <input type="password" name="parola" placeholder="Parola" [(ngModel)]="register.password" required="required">
                </div>
            </div>
            <div class="field">
                <div class="ui input">
                    <input type="password" name="confirmaParola" placeholder="Confirmă Parola" [(ngModel)]="register.confirmPassword" required="required">
                </div>
            </div>
            <button class="ui fluid black submit button login" [ngClass]="{'loading':loading}" (click)="save()" [disabled]="loading">Salvează</button>
        </div>
    </form>
    <br />
    <small>
        <strong>
            <div class="ui centered grid">
                <div class="row">
                    <div class="sixteen wide computer sixteen wide mobile column centered aligned">
                        <small [routerLink]="authLogin">
                            <strong>Înapoi la login</strong>
                        </small>
</div>
</div>
</div>
</strong>
</small>
</div>