import { Component, AfterViewInit, OnInit } from '@angular/core';
import { matchRole } from '~shared/helpers/global.func';
import { AuthService } from '~shared/services/auth/auth.service';
import { AppNotificationService, IAppNotificationSubscription } from '~shared/services/notification.service';


@Component({
    selector: 'header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss']
})

export class HeaderComponent implements AfterViewInit, OnInit {
    user: any;
    matchRole: Function;

    constructor(private auth: AuthService, private appNotification: AppNotificationService) {
        this.appNotification.keepUpdated('updateProfile', <IAppNotificationSubscription>{
            component: 'HeaderComponent', callback: (response) => {
                this.ngOnInit();
            }
        });
        this.matchRole = matchRole.bind(this);

    }

    ngOnInit() {
        this.auth.getCurrentUser().subscribe((user) => {
            this.user = user;
        });
    }

    ngAfterViewInit() {
        // init sidebar
        ($('.ui.sidebar') as any)
            .sidebar({
                context: $('.ui.pushable.segment'),
                transition: 'push',
                dimPage: false,
                closable: false
            });

        // Open at init
        this.toggleSidebar();

        setTimeout(() => {
            let resizeEvent = new Event('resize');
            window.dispatchEvent(resizeEvent);
        }, 1000);
    }

    onResize(event) {
        if (event.target.innerWidth < 769) {
            this.toggleSidebar('hide');
        }
    }

    // show/hide the sidebar
    toggleSidebar(action?: string) {
        ($('.ui.sidebar') as any).sidebar(action || 'toggle');
        if (($('.ui.sidebar').hasClass('visible'))) {
            $('.pusher').addClass('tweaked');
        } else {
            $('.pusher').removeClass('tweaked');
        }
    }

    capitalize(text: string) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    logOut() {
        this.auth.logout(true);
    }
}