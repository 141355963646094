<div class="ui grid">
    <div class="five wide computer sixteen wide tablet sixteen wide mobile column background-gray">
        <div class="ui segment">
            <div class="ui grid stackable">
                <div class="thirteen wide computer five wide tablet sixteen wide mobile column">
                    <h3>Setările mele</h3>
                </div>

                <div class="three wide computer five wide tablet sixteen wide mobile column">
                </div>
            </div>

            <div class="ui divider"></div>

            <div class="ui grid" *ngIf="user">
                <div class="row">
                    <div class="sixteen wide column border-top padding-top-15">
                        <div>
                            <strong>Nume:</strong>
                            <span class="right">{{user.lastName}}</span>
                        </div>

                        <div>
                            <strong>Prenume:</strong>
                            <span class="right">{{user.firstName}}</span>
                        </div>

                        <div>
                            <strong>Email:</strong>
                            <span class="right">{{user.email}}</span>
                        </div>


                        <div class="ui secondary user-tabs vertical menu" style="width:100%">
                            <a class="item" data-tab="settings" (click)="activeTab = 1" [ngClass]="{'active': activeTab === 1}">
                                <i class="user  icon"></i> Profilul meu
                            </a>
                            <a class="item" data-tab="change-password" (click)="activeTab = 2" [ngClass]="{'active': activeTab === 2}">
                                <i class="lock icon"></i> Schimbă parola
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="eleven wide computer sixteen wide tablet sixteen wide mobile column background-gray">
        <div class="ui segment">
            <settings *ngIf="activeTab === 1"></settings>
            <change-password *ngIf="activeTab === 2"></change-password>
        </div>
    </div>

</div>