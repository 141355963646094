<div class="ui middle aligned four column centered grid computer auth background-login" (window:resize)="onResize($event)">
    <div class="ten wide computer sixteen wide mobile twelve wide tablet column">
        <div [ngClass]="isMobile ? 'ui one column centered grid' : 'ui two column centered grid'">
            <div class="column aligned">
                <div class="ui grid">
                    <div class="row">

                    </div>
                </div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>