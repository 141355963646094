import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthNames, checkForm, handleSwaggerError, ValidationRules } from '~shared/helpers';
import { AuthService } from '~shared/services/auth/auth.service';
import { RegisterModel } from '~shared/services/auth/models';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit {
  loading: boolean;
  saving: boolean;

  errors: string[] = [];

  register = new RegisterModel();

  authLogin = '/' + AuthNames.prefix + '/' + AuthNames.login;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.applyFormValidation();
  }

  save() {
    if (!checkForm()) return;

    this.authService.register(this.register).subscribe((response) => {
      this.router.navigateByUrl('/' + AuthNames.prefix + '/' + AuthNames.activate);
    }, handleSwaggerError.bind(this, [{ setAlert: false }]))
  }

  // form validation
  applyFormValidation() {

    ($('.ui.form') as any)
      .form({
        fields: {
          email: {
            rules: ValidationRules.email
          },
          firstName: {
            rules: ValidationRules.empty
          },
          lastName: {
            rules: ValidationRules.empty
          },
          parola: {
            rules: ValidationRules.password
          },
          confirmaParola: {
            rules: ValidationRules.confirmPassword
          },
        },
        inline: true,
        on: 'blur'
      });
  }

}
