<div class="ui modal {{style}}" [ngClass]="identifier">
  <i class="close icon" *ngIf="!hideCloseBtn"></i>
  <div class="header">
      <div>
          <ng-content select='.modal-title'></ng-content>
      </div>
  </div>
  <div class="scrolling content">
      <div>
          <ng-content select='.modal-content'></ng-content>
      </div>
  </div>
  <div class="actions" *ngIf='!hideActions'>
      <div #ref>
          <ng-content select='.modal-actions'></ng-content>
      </div>
      <div *ngIf="!ref.children.length">
          <div class="ui negative mini left icon button">
              <i class="close icon"></i>
              Înapoi 
          </div>
          <div class="ui positive right mini lefticon button">
            <i class="checkmark icon"></i>

              Confirm
          </div>
      </div>
  </div>
</div>