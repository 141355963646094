<div class="ui vertical menu sidebar">
    <div class="item logo">
        <div class="ui small image">    
            <img [src]="'./assets/images/uap-logo.jpg'">                   
        </div>
    </div>
    <!-- <a class="item" [routerLink]="item.route" [routerLinkActive]="['active']" *ngFor="let item of routes">
        <div>
            <i class="icon" [ngClass]="item.iconClass"></i>
            <span>{{item.label}}</span>
        </div>
    </a> -->
    <ng-container *ngIf="routes">
        <div *ngFor="let item of routes">
            <div class="header">
                <div>
                    <i class="icon" [ngClass]="item?.icon"></i>
                    <span [innerHTML]="item?.label" class="ten"></span>
                </div>
            </div>
            <div class="menu">
                <a class="item" [routerLink]="comp.route" [routerLinkActive]="['active']"
                    *ngFor="let comp of item?.children">
                    <div>
                        <span [innerHTML]="comp.label" class="ten"></span>
                    </div>
                </a>
            </div>
        </div>
    </ng-container>  
</div>