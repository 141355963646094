import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { checkForm, checkForPartialUpdate, handleSwaggerError, setAlert } from 'src/shared/helpers/global.func';
import { Resource, ResourceService, ResourceType } from 'src/shared/services/resource/resource.service';
import {AuthNames, Role, ValidationRules} from '~shared/helpers';
import { AuthService } from '~shared/services/auth/auth.service';
import { BaseAddComponent } from '~shared/helpers/classes/base-component/base-add-component';
import { cloneDeep } from 'lodash-es';
import { UserModel } from '~shared/services/user/models';
import { UserService } from '~shared/services/user/user.service';

const empty = {
  rules: ValidationRules.empty
};

const fields = {
  'nume': empty,
  'prenume': empty,
  'email': { rules: ValidationRules.email },
  'role': { rules: ValidationRules.dropdown }
};

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent extends BaseAddComponent<UserModel> implements AfterViewInit, OnInit {
  resourceType = ResourceType;
  isSuperadmin = false;

  role = Role;
  roles: Resource[] = [];
  initialModel = new UserModel();


  constructor(route: ActivatedRoute, router: Router, service: UserService,
              private auth: AuthService, private resource: ResourceService) {
    super(service, UserModel, fields, 'Utilizatorul', route, router);
  }

  override ngOnInit() {

    this.route.params.subscribe((params) => {
      if (params.id) this.id = Number(params.id);

      if (this.id != 0) {
        this.service.getSingle(this.id).subscribe((item) => {
          this.model = item;
          this.initialModel = cloneDeep(item);
        }, handleSwaggerError.bind(this));
      }

      this.resource.getResource(ResourceType.Roles).subscribe((roles) => {
        this.roles = cloneDeep(roles);
        //remove member role
        if (this.id) this.roles.splice(2, 1);
      });

    });
  }

  override save() {
    if (!checkForm()) return;

    this.loading = true;

    const model = checkForPartialUpdate(this.initialModel, this.model);
    if (this.model.id) {
      this.service.patch(model).subscribe(() => {
        this.loading = false;

        setAlert('success', "Utilizatorul a fost " + (this.model.id ? 'editat' : 'creat') + " cu succes");

        this.router.navigateByUrl('manage-users');
      }, handleSwaggerError.bind(this, [{setAlert: true}]));
    } else {
      console.log('register')

      if (!checkForm()) return;

      this.auth.register({
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        email: this.model.email,
        password: this.model.password,
        confirmPassword: this.model.confirmPassword
      }).subscribe((response) => {
        this.router.navigateByUrl('/' + AuthNames.prefix + '/' + AuthNames.activate);
      }, handleSwaggerError.bind(this, [{ setAlert: false }]))
    }
  }

}
