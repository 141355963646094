import { numberToWords } from './../../../../shared/helpers/number.to.words';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.scss']
})
export class PaymentReceiptComponent implements OnInit {
  @Input() showPrintOut = false
  @Input() artist = null;
  @Input() debtsAfterCalculation = []
  @Input() valueDate = new Date();
  @Input() invoiceNumber
  @Input() amountToPay

  constructor() { }

  ngOnInit(): void {
  }

  get totalInWords() {
    return numberToWords(Number(this.amountToPay))
  }




}
