<div class="ui segment">
  <div class="ui grid stackable">
    <div class="four wide computer five wide tablet sixteen wide mobile column">
      <h3>Filiala</h3>

    </div>

    <div class="six wide computer five wide tablet sixteen wide mobile column">
    </div>
    <div class="three wide computer five wide tablet sixteen wide mobile column">
      <a class="ui mini fluid  button icon left" (click)="exportExcel()">
        <i class="download icon"></i>
        <span>Exportă filiale</span>
      </a>
    </div>
    <div class="three wide computer five wide tablet sixteen wide mobile column">
      <a class="ui mini fluid blue  button icon left" [routerLink]="0">
        <i class="plus square icon"></i>
        <span>Adaugă filiala nouă</span>
      </a>
    </div>
  </div>

  <div class="ui divider"></div>
  <div class="ui grid stackable">

    <div class="three wide computer only column">

    </div>

    <div class="eleven wide computer only column"></div>
    <div class="two wide computer three wide tablet sixteen wide mobile column">
      <fm-dropdown [list]="pagination.itemsPerPageList" [(option)]="pagination.itemsPerPage"
        (change)="pagination.changeItemsPerPage(pagination.itemsPerPage)" [required]="true"></fm-dropdown>
    </div>
  </div>
  <div class="ui divider"></div>


  <no-data *ngIf="!data.length && !loading && !error"></no-data>
  <app-loader *ngIf="loading"></app-loader>
  <invalid-request *ngIf="error" [(refresh)]="refresh"></invalid-request>

  <section [hidden]="loading || !data.length">
    <div class="table-container" *ngIf="!error">
      <table class="ui very basic sortable table">
        <thead>
          <tr>
            <th *ngFor="let column of columns" class="sorted">{{column.label}}</th>
            <th style="cursor: default;" class="sorted">Acțiuni</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="let item of data | paginate: { id:'data', itemsPerPage: pagination.itemsPerPage, currentPage: pagination.page, totalItems: pagination.totalNumberOfItems }; let index = index">
            <tr [hidden]="loading">
              <td [innerHTML]="item.name"></td>
              <td [innerHTML]="item.code"></td>

              <td>
                <a [routerLink]="'/manage-branch/' + item.id">
                  <i class="edit icon"></i>
                </a>
                |
                <a (click)="delete(item.id)">
                  <i class="close icon"></i>
                </a>
              </td>
            </tr>
          </ng-container>
        </tbody>
        <tfoot [hidden]="!data.length">
          <tr>
            <th [attr.colspan]="columns.length + 2" [hidden]="!data.length">
              <pagination-controls (pageChange)="pagination.changePage($event)" id="data" [previousLabel]="'Anteriorul'"
                [nextLabel]="'Următorul'">
              </pagination-controls>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </section>
</div>
