import { Role } from "./global.enum";

export interface IRouteComponent{
    label:string;
    icon: string;
    children: Array<IRouteChildComponent>;
}

export interface IRouteChildComponent{
    label: string;
    route: string;
    access: Role[];
}