import { Component, AfterContentInit } from '@angular/core';
import { CallbackEvent } from '~shared/fomantic';
import { openModal } from '~shared/helpers/global.func';
@Component({
    selector: 'footer',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.scss']
})

export class FooterComponent implements AfterContentInit {
    public termsIdentifier: string = 'terms-and-conditions';
    public termsAndConditions: string;
    public privacyPolicyIdentfier: string = 'privacy-policy-identifier';
    public privacyPolicy: string;

    openModal: (selector: string) => void;

    constructor() {
        this.openModal = openModal.bind(this);       
    }

    ngAfterContentInit() {

    }

    termsCallback(data: CallbackEvent) {
        if (data.type === 'onShow') {
            $('.privacy-policy-link').on('click', () => {
                this.openModal(this.termsIdentifier);
                setTimeout(() => {
                    this.openModal(this.privacyPolicyIdentfier);
                }, 200);
            });
        }
        if (data.type === 'onHide') {
            $('.privacy-policy-link').off('click');
        }
    }
}