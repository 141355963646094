import { Injectable } from '@angular/core';
import { find, each, filter } from 'lodash-es';

interface IAppNotificationSubscription {
    component: string;
    callback: (data?: any) => void;
}

type AppNotificationEvent = 'updateProfile';

@Injectable({
    providedIn: 'root'
})
class AppNotificationService {

    private keepTrack: { [event in AppNotificationEvent]: IAppNotificationSubscription[] } =
        {
            updateProfile: []
        };

    constructor() { }

    keepUpdated(event: AppNotificationEvent, subscription: IAppNotificationSubscription) {
        const duplicate = find(this.keepTrack[event], { component: subscription.component });
        if (duplicate) {
            duplicate.callback = subscription.callback;
        }
        else {
            this.keepTrack[event].push(subscription);
        }
    }

    refresh(event: AppNotificationEvent, data?: any) {
        each(this.keepTrack[event], (subscription: IAppNotificationSubscription) => {
            //  console.log('Refresh on component:', subscription.component, ' for event:', event);
            subscription.callback(data);
        });
    }

    clearUpdated(component: string, event?: AppNotificationEvent) {

        if (!event) {
            each(this.keepTrack, (subscriptions, evt) => {
                this.keepTrack[evt] = filter(subscriptions, (subscription) => subscription.component !== component);
            });
            return;
        }

        let found = find(this.keepTrack[event], { component });
        if (!found) {
            find(this.keepTrack, (subscriptions: IAppNotificationSubscription[]) => {
                found = find(subscriptions, { component });
                return found ? true : false;
            });
            if (found) {
                console.log('Found component in another event:', event);
            }
        } else {
            this.keepTrack[event].splice(this.keepTrack[event].indexOf(found), 1);
        }
    }
}

export { AppNotificationService, IAppNotificationSubscription, AppNotificationEvent };
