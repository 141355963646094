import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class BranchService extends BaseService {
  constructor() {
    super('branch');
  }
  downloadFile() {
    const headers = { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    let url = `api/report/export-branch`;

    this.http.get(url, { headers, responseType: 'blob' }).subscribe({
      next: (response: Blob) => {
        saveAs(response, 'Listă filiale.xlsx');
      },
      error: (err: Error) => {
        console.error(err.name, err.message);
      },
      complete: () => {},
    });
  }
}
