import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { AuthService } from '~shared/services/auth/auth.service';
import { MemberShortModel } from '~shared/services/member/models/models';

@Component({
    selector: 'not-found',
    templateUrl: 'not-found.component.html',
    styleUrls: ['not-found.component.scss']
})

export class NotFoundComponent implements AfterViewInit, OnDestroy {
    user: MemberShortModel;

    constructor(private auth: AuthService) { }

    logOut() {
        this.auth.logout(true);
    }

    ngAfterViewInit() {
        $('body').css('overflow', 'hidden');
        this.auth.getCurrentUser().subscribe((user) => {
            this.user = user;
        });
    }

    ngOnDestroy() {
        $('body').css('overflow', 'initial');
    }
};