<div class="ui grid stackable">
  <div class="thirteen wide computer five wide tablet sixteen wide mobile column">
    <h3 class="component-heading">Plăți
    </h3>
  </div>

  <!-- <div class="three wide computer five wide tablet sixteen wide mobile column">

    </div> -->
</div>
<div class="ui divider"></div>
<div class="ui grid">
  <div class="row" *ngIf="!currentArtistId">
    <div class="sixteen wide tablet four wide computer column">
      <fm-search [type]="resourceType.Artists" [placeholder]="'Caută artist'" [minCharachers]="1"
        (onChange)="fetchData($event)">
      </fm-search>
    </div>
  </div>

  <div class="row" *ngIf="artist">
    <div class="sixteen wide computer column">
      <div class="ui grid">
        <div class="four wide column">
          <div class="ui raised segment">
            <a class="ui green ribbon label">Detalii artist</a>
            <form class="ui form create event" autocomplete="off" *ngIf="!loading">
              <div class="ui mini error message"></div>
              <div class="ui mini red message" *ngIf="errors.length">
                <ul class="list">
                  <li *ngFor="let error of errors" [innerHTML]="error"></li>
                </ul>
              </div>
              <div class="two fields">
                <div class="field required">
                  <label>Nume</label>
                  <div class="ui disabled input">
                    <input type="text" name="nume" placeholder="Nume" [(ngModel)]="artist.firstName" />
                  </div>
                </div>

                <div class="field required">
                  <label>Prenume</label>
                  <div class="ui disabled input">

                    <input type="text" name="prenume" placeholder="Prenume" [(ngModel)]="artist.lastName" />
                  </div>
                </div>
              </div>

              <div class="two fields">
                <div class="field required">
                  <label>Cod</label>
                  <div class="ui disabled input">

                    <input type="text" name="cod" placeholder="Cod" [(ngModel)]="artist.code" />
                  </div>
                </div>

                <div class="field required">
                  <label>CNP</label>
                  <div class="ui disabled input">

                    <input type="number" name="ssn" placeholder="CNP" [(ngModel)]="artist.ssn" />
                  </div>
                </div>
              </div>
              <div class="two fields">
                <div class="field required">
                  <label>Locul Nașterii</label>
                  <div class="ui disabled input">

                    <input type="text" name="loculNasterii" placeholder="Locul Nașterii"
                      [(ngModel)]="artist.birthPlace" />
                  </div>
                </div>

                <div class="field required">
                  <label>Data nașterii</label>
                  <div class="ui disabled input">
                    <input type="text" [(ngModel)]="artist.birthDate" name="birthDate" />
                  </div>

                </div>
              </div>
              <div class="two fields">
                <div class="field required">
                  <label>Adresa de email</label>
                  <div class="ui disabled input">

                    <input type="email" name="email" placeholder="Adresa de email" [(ngModel)]="artist.email" />
                  </div>

                </div>

                <div class="field required">
                  <label>Număr de telefon</label>
                  <div class="ui disabled input">

                    <input type="text" name="numarDeTelefon" placeholder="Număr de telefon"
                      [(ngModel)]="artist.phoneNumber" />
                  </div>

                </div>
              </div>
              <div class="five fields">


                <div class="field required">
                  <label>Calitate</label>
                  <div class="ui disabled input">

                    <input type="text" name="calitate" placeholder="Calitate" [(ngModel)]="artist.quality" />
                  </div>

                </div>

              </div>

            </form>



          </div>
        </div>
        <div class="twelve wide column">
          <div class="ui segment">
            <a class="ui orange right ribbon label">Plăți</a>

            <div class="ui grid">
              <div class="row">
                <div class="sixteen wide tablet sixteen wide computer column">
                  <!-- replace form with add payment component -->
                  <app-add-payment (setParentLoading)="setLoading($event)" [paymentsType]="paymentsType"
                    [recipientTypes]="recipientTypes" [artistPayment]="artistPayment" [artistId]="pagination.artistId"
                    (onSaveSuccess)="onSaveSuccess()">
                  </app-add-payment>
                </div>
              </div>
              <div class="ui divider"></div>

              <div class="ui segment payment-table" style="width: 100%;">

                <div class="ui grid stackable">

                  <div class="three wide computer only column">

                  </div>

                  <div class="eleven wide computer only column"></div>
                  <div class="two wide computer three wide tablet sixteen wide mobile column">
                    <fm-dropdown [list]="pagination.itemsPerPageList" [(option)]="pagination.itemsPerPage"
                      (change)="pagination.changeItemsPerPage(pagination.itemsPerPage)" [required]="true"></fm-dropdown>
                  </div>
                </div>
                <div class="ui divider"></div>


                <no-data *ngIf="!data.length && !loading && !error"></no-data>
                <app-loader *ngIf="loading"></app-loader>
                <invalid-request *ngIf="error" [(refresh)]="refresh"></invalid-request>

                <section [hidden]="loading || !data.length">
                  <div class="table-container" *ngIf="!error">
                    <table class="ui very basic sortable table">
                      <thead>
                        <tr>
                          <th *ngFor="let column of columns" class="sorted">{{column.label}}
                          </th>
                          <th style="cursor: default;" class="sorted">Acțiuni</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container
                          *ngFor="let item of data | paginate: { id:'data', itemsPerPage: pagination.itemsPerPage, currentPage: pagination.page, totalItems: pagination.totalNumberOfItems }; let index = index">
                          <tr [hidden]="loading">
                            <td [innerHTML]="item.name"></td>
                            <td [innerHTML]="item.amount"></td>
                            <td [innerHTML]="matchPaymentType(item.paymentTypeId)"></td>
                            <td [innerHTML]="matchRecepientType(item.recipientTypeId)"></td>

                            <td>
                              <a (click)="download(item)">
                                <i class="download icon"></i>
                              </a>
                              |
                              <a (click)="delete(item.id)">
                                <i class="close icon"></i>
                              </a>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                      <tfoot [hidden]="!data.length">
                        <tr>
                          <th [attr.colspan]="columns.length + 2" [hidden]="!data.length">
                            <pagination-controls (pageChange)="pagination.changePage($event)" id="data"
                              [previousLabel]="'Anteriorul'" [nextLabel]="'Următorul'">
                            </pagination-controls>
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </section>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
