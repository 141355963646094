import { Component, Input, OnDestroy } from '@angular/core';
import { BaseComponent } from '~shared/helpers/classes/base-component/base-component';
import { Column } from '~shared/helpers/classes/pagination/pagination';
import { StatutHistoryService } from '~shared/services/statut-history/statut-history.service';
import { StatutHistoryModel } from '~shared/services/statut-history/model';
import { ActivatedRoute } from '@angular/router';
import { ArtistService } from '~shared/services/artist/artist.service';

const columns = [
  new Column('oldStatus', 'Statut vechi'),
  new Column('newStatus', 'Statut nou'),
  new Column('dateTransfer', 'Data schimbării'),
];

@Component({
  selector: 'app-manage-statut-history',
  templateUrl: './manage-statut-history.component.html',
  styleUrls: ['./manage-statut-history.component.scss']
})
export class ManageStatutHistoryComponent extends BaseComponent<StatutHistoryModel> implements OnDestroy {
  @Input() selectedArtistId: number;

  override columns = columns;
  statutHistory: StatutHistoryModel[] = [];
  constructor(service: StatutHistoryService, private route: ActivatedRoute, private artistService: ArtistService) {
    super(service, 'statutHistory');
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.loading = true;
    this.service.getStatutHistoryArtist(this.selectedArtistId).subscribe(
      (data) => {
        this.statutHistory = data.map((item) => ({...item}));
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.error = true;
      }
    );
  }
}
