
import { Component, Input } from '@angular/core';

@Component({
    selector: 'no-data',
    template: `
    <div class="no data">
        <br/>
        <i class="big icons">
            <i class="database icon"></i>
            <i class="red corner remove icon"></i>
        </i> <span [innerHTML] = 'text'></span>
    </div>`,
    styles: ['.no.data{ padding: 15px; color:grey; font-size:12px; text-align:center } .no.data span{ display: block }']
})
export class NoDataComponent {
    @Input() text: string = 'Nu sunt date disponibile';
    constructor() { }
}
