<div class="ui segment status">
  <div class="ui grid stackable">
    <div class="thirteen wide computer five wide tablet sixteen wide mobile column">
      <div class="ui large breadcrumb">
        <a class="section" [routerLink]="'/manage-branch'">Filiale</a>
        <i class="right chevron icon divider"></i>
        <h3 class="section active" [innerHTML]="id ? 'Editează filiala' : 'Adaugă filiala'">
        </h3>
      </div>
    </div>
    <div class="three wide computer five wide tablet sixteen wide mobile column">
      <button class="ui mini blue right floated button" (click)="save()" [ngClass]="{'loading': loading}"
        [disabled]="loading">
        <i class="save icon"></i>
        <span>Salvează</span>
      </button>
    </div>
  </div>


  <div class="ui divider"></div>
  <div class="ui grid">
    <div class="sixteen wide computer twelve wide table sixteen wide mobile column">
      <form class="ui form create event" autocomplete="off" *ngIf="!loading">
        <div class="ui mini error message"></div>
        <div class="ui mini red message" *ngIf="errors.length">
          <ul class="list">
            <li *ngFor="let error of errors" [innerHTML]="error"></li>
          </ul>
        </div>
        <div class="three fields">

          <div class="field required">
            <label>Nume</label>
            <input type="text" name="nume" placeholder="Nume" [(ngModel)]="model.name" />
          </div>
        </div>
        <div class="three fields">
          <div class="field required">
            <label>Cod</label>
            <input type="text" name="cod" placeholder="Cod" [(ngModel)]="model.code" />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
