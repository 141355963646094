import { base64ArrayBuffer, handleSwaggerError, setAlert, checkForm } from 'src/shared/helpers/global.func';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseAddComponent } from '~shared/helpers/classes/base-component/base-add-component';
import { ValidationRules, FILE_NAME_REGEX, Months } from './../../../../shared/helpers/global.const';
import { ArtistPaymentService } from './../../../../shared/services/artist-payment/artist-payment.service';
import { ArtistPaymentModel } from './../../../../shared/services/artist-payment/models';
import { IResource } from '~shared/services';

const fields = {
  'amount': { rules: ValidationRules.empty },
  'paymentType': { rules: ValidationRules.dropdown },
  'recipientType': { rules: ValidationRules.dropdown },
  'month': { rules: ValidationRules.dropdown },
  'year': { rules: ValidationRules.empty },
  'doc': { rules: ValidationRules.file }
};


@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent extends BaseAddComponent<ArtistPaymentModel> implements OnInit {

  @Input() artistPayment: ArtistPaymentModel;
  @Input() onSave: Function;
  @Input() artistId: number;
  @Output() onSaveSuccess: EventEmitter<any> = new EventEmitter();
  @Output() setParentLoading: EventEmitter<any> = new EventEmitter();
  @Input() recipientTypes: Array<any> = []
  @Input() paymentsType: Array<any> = []
  @ViewChild('docFile', { static: false }) fileInput: ElementRef;

  file: File;
  acceptedDocType = '.pdf';

  months: IResource[] = Months;

  formId = 'payment-form';

  constructor(service: ArtistPaymentService, router: Router, route: ActivatedRoute) {
    super(service, ArtistPaymentModel, fields, 'plata', route, router, true);
  }

  override ngAfterViewInit() {
    setTimeout(() => {
      ($(`#${this.formId}`) as any).form({
        inline: true,
        fields: this.fields
      });

      this.artistPayment.artistId = this.artistId;
    }, 10);
  }


  override save() {

    if (!checkForm(`#${this.formId}`)) return;

    this.loading = true;
    this.setParentLoading.emit(true);
    this.service.save(this.artistPayment).subscribe((res) => {
      setAlert('success', 'Plata a fost înregistrată cu succes!');
      this.loading = false;
      this.setParentLoading.emit(false);
      this.file = null;
      this.onSaveSuccess.emit();

    }, (error: any) => {
      handleSwaggerError.bind(this, [{ setAlert: true }])(error);
      this.loading = false;
      this.setParentLoading.emit(false);
    })
  }



  prepareFile = (event) => {
    if (!this.checkExt(event, this.acceptedDocType)) return;
    const reader = new FileReader();
    this.file = event;
    reader.readAsArrayBuffer(event);
    reader.onload = () => {
      this.artistPayment.payment = base64ArrayBuffer(reader.result);
      this.artistPayment.artistId = this.artistId;
      this.artistPayment.name = event.name;
    }
    this.onDropDownChange();
  }

  private checkExt(file: File, types: string): Boolean {

    const ext = FILE_NAME_REGEX.exec(file.name)[1];

    if (types.indexOf(ext.toLowerCase()) === -1) {
      setAlert('error', "Formatul ." + ext + " nu este acceptat");
      return false;
    }
    return true;
  }


  onDropDownChange = () => {
    setTimeout(() => {
      checkForm(`#${this.formId}`);
    }, 100)
  }

  onRemoveFile() {
    this.file = null;
    this.artistPayment.payment = null;
    this.fileInput.nativeElement.value = null;
    this.onDropDownChange();
  }

  get isSaveDisabled() {
    return !this.artistPayment.month ||
      !this.artistPayment.amount ||
      !this.artistPayment.paymentTypeId ||
      !this.artistPayment.year ||
      !this.artistPayment.payment;
  }

}
