import { ServerPagination } from './../../helpers/classes/pagination/pagination';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArtistDebtService extends BaseService {
  constructor() {
    super('debt');
  }

  list(artistId): Observable<Array<any>> {
    const pagination = {
      itemsPerPage: 1000000,
      page: 1,
      orderField: 'id desc',
      artistId
    }
    const params = new HttpParams({
      fromString: (new ServerPagination(pagination).queryParams())
    });
    return this.http.get(this.url, { params, observe: 'response' }).pipe(map((response) => {
      return response.body as Array<any> || [];
    }));
  }
}
