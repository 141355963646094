<div class="container">

  <div class="receipt-container">
    <div class="institution-info">
      <div class="main-info">
        <div class="inof-0">
          <div>
            <span>Seria : <b>UAP</b></span>
          </div>
          <span>nr. <b>{{invoiceNumber}}</b></span>
        </div>
        <div class="inof-1">
          <div>
            <span>Unitatea : <b>UNIUNEA ARTIȘTILOR PLASTICI DIN ROMÂNIA</b></span>
          </div>
          <span> Nr.R.C/Aut :NR </span>
        </div>
        <div class="info-2">
          <div>
            <span> C.I.F : RO 1590350</span>
          </div>
          <span>Cap.Soc.:</span>
        </div>
        <div class="info-3">
          <span>Sediul :Bucharest, Str.Baiculesti, nr.29,Sector 1</span>
        </div>
        <div class="info-4">
          <div>
            <span>Cont :RO83BACX0000003005108002</span>
          </div>
          <div>
            <span>Banca: UniCredit Tiriac Bank</span>
          </div>
        </div>
      </div>
    </div>
    <div class="receipt-box">
      <h3>CHITANȚA</h3>
      <form class="ui form create event">
        <div class="one field">
          <div class="field ">
            <label>Nr.</label>
            <input type="text" [disabled]="true" [value]="invoiceNumber" />
          </div>
        </div>
        <div class="one field">
          <div class="field ">
            <label>Data (ziua,luna,anul)</label>
            <input type="text" [disabled]="true" value="{{valueDate | date: 'dd/MM/yyyy'}}" />
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="receipt-fields">
    <form class="ui form create event" autocomplete="off">
      <div class="one field">
        <div class="field ">
          <label>Am primit de la </label>
          <input type="text" [disabled]="true" value="{{artist.lastName}} {{artist.firstName}}" />
        </div>
      </div>
      <div class="two fields">
        <div class="field ">
          <label>C.I.F </label>
          <input type="text" [disabled]="true" value="" />
        </div>

        <div class="field ">
          <label>Nr.Ord.Reg.Com </label>
          <input type="text" [disabled]="true" value="" />
        </div>
      </div>
      <div class="one field">
        <div class="field ">
          <label>Adresa </label>
          <input type="text" [disabled]="true" value="{{artist.address}}" />
        </div>
      </div>
      <div class="two fields total-fields">
        <div class="field">
          <label>Suma de</label>
          <input type="text" [disabled]="true" value="{{amountToPay | currency :' RO '}}" />
        </div>

        <div class="field">
          <label>adică </label>
          <input type="text" [disabled]="true" value="{{ totalInWords | uppercase }} LEI" />
        </div>
      </div>
      <div class="two fields">

        <div class="field payments">
          <label>Reprezentând</label>
          <div class="list">
            <input *ngFor="let debt of debtsAfterCalculation" type="text" [disabled]="true"
              value="{{debt.year}} - {{debt.paid}}  Lei " />
          </div>
        </div>

      </div>
      <div class="two fields sign-field">
        <div class="field ">
        </div>

        <div class="field">
          <label>Casier </label>
          <input type="text" [disabled]="true" value="" />
        </div>
      </div>
    </form>
  </div>

</div>
