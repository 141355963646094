import { Component, Input, AfterViewInit, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';

import { isArray, map, isNumber } from 'lodash-es';

declare var $: any;
// import { ResourcesEnum, ResourceService, IResource } from 'services';

interface IListItem {
    id?: number; value?: string | number; name?: string; selected?: boolean;
}
// Mandatory value & selected values
interface IDropdownListItem extends IListItem {
    value: string | number;
    selected?: boolean;
    isNew?: boolean;
    label?: string | number;

}

@Component({
    selector: 'fm-dropdown-multiple',
    templateUrl: 'dropdown-multiple.component.html',
    // styleUrls: ['dropdown-multiple.component.css']
})
export class FMDropdownMultipleComponent implements AfterViewInit, OnDestroy {

    @Input() placeholder: string = 'Select a value';
    @Input() options: IDropdownListItem[] = [];
    currentType: number = 0;
    private timeout: any = null;

    selectedOptions: IDropdownListItem[] = [];

    @Output() optionsChange: EventEmitter<IDropdownListItem[]> = new EventEmitter<IDropdownListItem[]>();

    // @Input() set remoteSource(type: ResourcesEnum) {
    //     if (type) {
    //         // this._resourceService
    //         //     .getResource(type)
    //         //     .subscribe((response: IResource[]) => {
    //         //         this.list = response;
    //         //     }, (error) => {
    //         //         console.error('Error loading remove source:', error);
    //         //         this.list = [];
    //         //     });
    //         this.currentType = type;
    //     }
    // };

    // getFilteredResource(type: ResourcesEnum, searchText: string | number) {
    //     if (type && searchText !== '') {
    //         this._resourceService
    //             .getFilteredResource(type, searchText)
    //             .subscribe((response: IResource[]) => {
    //                 this.list = [];
    //                 this.list = response;

    //                 this.selectedOptions.forEach((item) => {
    //                     this.dropdownList.push({
    //                         value: item.value, name: item.name,
    //                         selected: item.selected, isNew: item.isNew
    //                     });
    //                 });
    //             }, (error) => {
    //                 console.error('Error loading remove source:', error);
    //                 this.list = [];
    //             });
    //     }
    // }

    @Input() set list(list: IListItem[]) {

        let data = list || [];

        if (!isArray(data)) {
            console.error('List should not be an object:', data);
            list = [];
        }

        data = map(data, (item: IListItem) => {
            item.value = item.value || item.name;
            item.selected = item.selected || false;
            return item;
        });

        this.dropdownList = (data as IDropdownListItem[]);
    }

    dropdownList: IDropdownListItem[] = [];
    // for more settings and details : https://semantic-ui.com/modules/dropdown.html#/settings
    private pOptions = {
        // Event used to trigger dropdown (Hover, Click, Custom Event)
        on: 'click',
        // Whether values with matching cases should be treated as identical when adding them to a dropdown.
        // ignoreCase: true,
        // // When set to true will fire onChange even when the value a user select matches the currently selected value.
        // allowReselection: false,
        // // Whether search selection should allow users to add their own selections, works for single or multiselect.
        // allowAditions: false,
        // // The minimum characters for a search to begin showing results
        // minCharacters: 1,
        // Whether search selection will force currently selected choice when element is blurred.
        forceSelection: false,
        // 	Whether multiselect should use labels. Must be set to true when allowAdditions is true
        useLabels: true,
        // 	When set to a number, sets the maximum number of selections
        maxSelections: false,
        // Maximum glyph width, used to calculate search size. This is usually size of a "W" in your font in em
        // glyphWidth: 1.0714
        // Allows customization of multi-select labels
        // label: {
        //     transition: 'horizontal flip',
        //     duration: 200,
        //     variation: false
        // }

        // Is called after a dropdown selection is added using a multiple select dropdown, only receives the added value
        onAdd: this.onAdd.bind(this),
        // Is called after a dropdown selection is removed using a multiple select dropdown, only receives the removed value
        onRemove: this.onRemove.bind(this),
        // Is called after a dropdown value changes. Receives the name and value of selection and the active menu element
        onChange: this.onChange.bind(this),

        message: {
            noResults: 'Write a value and press enter!'
        }
    };

    // , private _resourceService: ResourceService
    constructor(public element: ElementRef) { }

    ngAfterViewInit() {

        ($(this.element.nativeElement.children[0]) as any).dropdown(this.pOptions);

        // setTimeout(() => {

        //     const searchInput =
        //         $(this.element.nativeElement).children().find('input.search');

        //     searchInput.keyup((e) => {

        //         if (e.keyCode !== 13) {
        //             if (this.timeout) {
        //                 window.clearTimeout(this.timeout);
        //             }
        //             this.timeout = window.setTimeout(() => {
        //                 this.timeout = null;
        //                 this.getFilteredResource(this.currentType, searchInput.val() as string | number);
        //             }, 500);
        //         }

        //         if (e.keyCode === 13) {
        //             this._addNewOption(searchInput.val() as string | number);
        //             searchInput.val('');
        //         }
        //     });
        // }, 500);
    }

    ngOnDestroy() {
        ($(this.element.nativeElement.children[0]) as any).dropdown('clear');
    }

    private addNewOption(text: string | number) {
        if (isNumber(text) || text.length < 3) {
            // please add at least 3 characters?
        } else {
            this.dropdownList.push({ value: text, name: text, selected: true, isNew: true });
            setTimeout(() => {
                ($(this.element.nativeElement.children[0]) as any).dropdown('set selected', [text]);
            }, 100);
        }
    }

    private onAdd(value, text, $choice) {
        this.selectedOptions.push({ value: text, name: text, selected: true, isNew: true });
    }

    private onRemove(value) {
        this.selectedOptions.splice(this.selectedOptions.indexOf(value), 1);
    }

    private onChange(value, text, $choice) {
        this.optionsChange.emit(this.selectedOptions);
    }

}
