import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthNames, ValidationRules } from '~shared/helpers';
import { checkForm, handleSwaggerError, setAlert } from '~shared/helpers/global.func';
import { MemberService } from '~shared/services/member/member.service';
import { ResetPasswordModel } from '~shared/services/member/models/models';


@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  email: string;
  gotResetCode = false;
  resetPassword = new ResetPasswordModel();

  loading = false;
  errors = [];
  loginLink = '/' + AuthNames.prefix + '/' + AuthNames.login;

  constructor(private service: MemberService, private activeRoute: ActivatedRoute, private router: Router) {
  }

  sendResetCode() {
    this.loading = true;
    this.errors = [];
    this.service.sendResetPasswordCode(this.email).subscribe((memberId) => {
      // this.router.navigateByUrl('/auth/login');
      this.gotResetCode = true;
      this.resetPassword.memberId = memberId;
      this.applyFormValidation();
      this.loading = false;
      setAlert('success', 'Dacă adresa de e-mail este validă, codul de resetare al parolei a fost trimis.')
    }, handleSwaggerError.bind(this, [{ setAlert: false }]));
  }

  reset() {
    if (!checkForm()) return;
    this.loading = true;
    this.errors = [];

    this.service.resetPassword(this.resetPassword).subscribe((response) => {
      this.router.navigateByUrl(this.loginLink);
      this.loading = false;
      setAlert('success', 'Parola a fost resetată cu succes!');
    }, handleSwaggerError.bind(this, [{ setAlert: false }]));
  }


  // form validation
  private applyFormValidation() {

    setTimeout(() => {
      ($('.ui.form') as any)
        .form({
          fields: {
            parola: {
              rules: ValidationRules.password
            },
            confirmaParola: {
              rules: ValidationRules.confirmPassword
            },
            cod: {
              rules: ValidationRules.cod
            }
          },
          inline: true,
          on: 'blur'
        });
    }, 10);
  }
}
