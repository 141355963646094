import { Observable, Observer } from 'rxjs';
import { AppInjector, ErrorsConst } from './global.const';
import { each, isArray, isEqual, isNumber, isPlainObject, reduce } from 'lodash-es';
import { Role } from './global.enum';
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $: any;

export function asObservable(response: any) {
    return new Observable<any>((observer: Observer<any>) => {
        observer.next(response);
        observer.complete();
    });
}

export function handleSwaggerError(arg: any[], swaggerError: any) {
    this.loading = false;
    this.error = true;
    this.errors = [];
    let error = swaggerError.error;
    if (swaggerError.response) {
        error = JSON.parse(swaggerError.response)
    }

    if (error && error.errors) each(error.errors, (item, key) => { this.errors.push(error.errors[key]) })
    else if (error && error.Message) {
        this.errors.push(error.Message);
    }

    this.errors = this.errors.length ? this.errors : [ErrorsConst.internal];
    if (arg[0]?.setAlert) {
        setAlert('error', this.errors);
        if (arg[0]?.skipErrors) this.error = false;
    }
}

export function setAlert(type: 'error' | 'success', message, timer = 3000) {
    Swal.fire({
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: timer
    })
}

export function checkForm(formSelector: string = '.ui.form') {
    ($(formSelector) as any).form('validate form');
    let valid = ($(formSelector) as any).form('is valid');
    return (isArray(valid) ? valid.indexOf(false) < 0 : valid);
}

export function formatProperty(text: string) {
    // ex: 'assignedUserBSOAndBSO'
    // step 1 => 'assigned User BSOAnd BSO'
    let transformed = text.replace(/\B([a-z](?=[A-Z]))/g, '$1 ');
    // step2 => 'assigned User BSO And BSO'
    transformed = transformed.replace(/\B([A-Z](?=[a-z]))/g, ' $1');
    // step 3 => 'Assigned User BSO And BSO
    transformed = transformed.charAt(0).toUpperCase() + transformed.slice(1);
    return transformed;
}

export function uniqueIdentifier(length = 10) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return 'unique-' + result;
}

export function openModal(selector: string, toggle = false) {
    ($('.ui.modal.' + selector) as any).modal(toggle ? 'toggle' : 'show');
}

export function matchRole(id) {
    if (!id) return;

    return String(Role[id]);
}

export function checkForPartialUpdate(original: any, updated: any) {
    let properties = [];
    properties = reduce(original, function (result, value, key) {
        if (updated.hasOwnProperty(key)) {
            return isEqual(value, updated[key]) ?
                result : result.concat(key);
        }
    }, []);

    let model = new Object();
    properties.push('id');
    each(properties, (prop) => {
        model[prop] = updated[prop];
    })
    return model;
}


export function getDifferences(newModel: { [id: string]: any }, oldModel: { [id: string]: any }): { [id: string]: any } {
    const diff = Object.keys(newModel).reduce((diff, key) => {
        if (oldModel[key] === newModel[key]) return diff
        return { ...diff, [key]: newModel[key] }
    }, {});
    return diff;
}

export function keyToLowerCase(key: string) {
    const formattedKey = formatProperty(key).split(/ (.+)/);
    return formattedKey.length > 1 ? (formattedKey[0].toLowerCase() + formattedKey[1].replace(/ /g, '')) : formattedKey[0].toLowerCase();
}


export function transformKeys(obj, toLowerCase: boolean): any | any[] {

    const transformedObj = isArray(obj) ? [] : {};
    // tslint:disable-next-line: forin
    for (const key in obj) {
        // replace the following with any transform function
        const transformedKey = isNumber(key) ? key : (toLowerCase ? keyToLowerCase(key) : key.replace(/^\w/, (c, _) => c.toUpperCase()));

        const isDictionaryOrArray = isPlainObject(obj[key]) || isArray(obj[key]);

        if (isDictionaryOrArray) {
            transformedObj[transformedKey] = transformKeys(obj[key], toLowerCase);
        } else {
            transformedObj[transformedKey] = obj[key];
        }
    }

    // console.log('transformedObj:', transformedObj);
    return transformedObj;
}

export function trackBy(index: number, item: any) {
    // if(!(item.id || item.property || item.name || item.refId)) console.log('Track by item: ', item);
    return item.id || item.property || item.name || item.refId || index;
}

export function base64ArrayBuffer(arrayBuffer) {
    let base64 = '';
    const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    const bytes = new Uint8Array(arrayBuffer);
    const byteLength = bytes.byteLength;
    const byteRemainder = byteLength % 3;
    const mainLength = byteLength - byteRemainder;

    // tslint:disable-next-line: one-variable-per-declaration
    let a, b, c, d;
    let chunk;

    // Main loop deals with bytes in chunks of 3
    for (let i = 0; i < mainLength; i = i + 3) {
        // Combine the three bytes into a single integer
        // tslint:disable-next-line: no-bitwise
        chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

        // Use bitmasks to extract 6-bit segments from the triplet
        // tslint:disable-next-line: no-bitwise
        a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
        // tslint:disable-next-line: no-bitwise
        b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
        // tslint:disable-next-line: no-bitwise
        c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
        // tslint:disable-next-line: no-bitwise
        d = chunk & 63;               // 63       = 2^6 - 1

        // Convert the raw binary segments to the appropriate ASCII encoding
        base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder === 1) {
        chunk = bytes[mainLength];

        // tslint:disable-next-line: no-bitwise
        a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

        // Set the 4 least significant bits to zero
        // tslint:disable-next-line: no-bitwise
        b = (chunk & 3) << 4; // 3   = 2^2 - 1

        base64 += encodings[a] + encodings[b] + '==';
    } else if (byteRemainder === 2) {
        // tslint:disable-next-line: no-bitwise
        chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];
        // tslint:disable-next-line: no-bitwise
        a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
        // tslint:disable-next-line: no-bitwise
        b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

        // Set the 2 least significant bits to zero
        // tslint:disable-next-line: no-bitwise
        c = (chunk & 15) << 2; // 15    = 2^4 - 1

        base64 += encodings[a] + encodings[b] + encodings[c] + '=';
    }

    return base64;
}
