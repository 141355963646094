<div class="ui top attached menu gradient inverted" (window:resize)="onResize($event)">
    <a (click)="toggleSidebar()" class="item">
        <i class="bars icon"></i>
    </a>
    <a class="item disabled" id="role">{{matchRole(user.roleId)}}</a>
    <div class="right menu">             
        <a class="ui item" routerLink="/profile">   
            <span [innerHTML]="user?.firstName + ' ' + user?.lastName"></span>  
            <span [innerHTML]="user?.firstName | firstLetter" class="symbol-label"></span>
        </a>      
        <div class="ui item" (click)="logOut()">   
            <i class="sign out alternate icon" ></i>
        </div>       
    </div> 
</div>