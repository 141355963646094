import { Directive, ElementRef } from '@angular/core';

declare var $: any;

@Directive({
    selector: '[FMTooltip]'
})
export class FMTooltipDirective {
    constructor(el: ElementRef) {
        setTimeout(()=>{
            $(el.nativeElement).popup();
        }, 100);
    }
}