import { Component } from '@angular/core';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
    isMobile = false;

    constructor() { }

    onResize(event) {
        if (event.target.innerWidth < 769) this.isMobile = true;
        else this.isMobile = false;
    }
}
