import { isObject } from 'lodash-es';
import { formatProperty } from '../../global.func';
import { Resource } from 'src/shared/services/resource/models';


export class Column {
    constructor(public property: string, public label?: string, public isSortable = false, public takeFrom = '', public sortAsc: boolean = false, public sortDesc: boolean = false) {
        this.label = label || (label !== null ? formatProperty(property) : '');
        this.takeFrom = takeFrom || property;
    }
}
export const ItemsPerPage = [
    new Resource(1, '10'),
    new Resource(2, '20'),
    new Resource(3, '50'),
    new Resource(4, '100')
];

interface BasePagination {
    page: number;
    itemsPerPage: number | string;
    orderField: string;
    searchBy?: string;
}

export class Pagination implements BasePagination {

    [id: string]: any;
    itemsPerPageList = ItemsPerPage;
    sortByInterval: number;
    sortByProperty: string;
    sortByDirection: string;

    orderField: string;

    lastSortByProperty: string;
    lastSortByDirection: string;
    constructor(
        public triggerMethod: (...args) => void, public page = 1,
        public itemsPerPage: number | string = ItemsPerPage[0].value as number,
        public count = 0) {
        if (!itemsPerPage) {
            this.itemsPerPage = this.itemsPerPageList[0].value as number;
        }
    }

    changePage(event) {
        this.page = event;
        this.triggerMethod();
    }

    changeItemsPerPage(event){
        this.itemsPerPage = event;
        this.triggerMethod();
    }

    sortBy(property: string) {

        window.clearTimeout(this.sortByInterval);

        if (this.sortByProperty === property) {
            switch (this.sortByDirection) {
                case null: {
                    this.sortByDirection = 'asc';
                    break;
                }
                case 'asc': {
                    this.sortByDirection = 'desc';
                    break;
                }
                case 'desc': {
                    this.sortByDirection = null;
                    break;
                }
            }
        }
        else {
            this.sortByProperty = property;
            this.sortByDirection = 'asc';
        }

        if (this.sortByProperty === this.lastSortByProperty &&
            this.sortByDirection === this.lastSortByDirection) {
            return;
        }

        // give time to do its job
        this.sortByInterval = window.setTimeout(
            () => {
                this.orderField = this.sortByDirection ? this.sortByProperty + ' ' + this.sortByDirection : null;
                this.triggerMethod();
                this.lastSortByProperty = this.sortByProperty;
                this.lastSortByDirection = this.sortByDirection;
            }, 0);
    }

    isSorted(property: string, asc = true) {
        return this.sortByProperty === property && this.sortByDirection === (asc ? 'asc' : 'desc');
    }
}



export class ServerPagination implements BasePagination {
    [dynamic: string]: any;
    public page = 1;
    public itemsPerPage: string | number = 10;
    public orderField = 'id desc';
    public searchBy?;

    constructor(data?: BasePagination) {
        for (const property in data) {
            if (data.hasOwnProperty(property)) {
                this[property] = data[property];
            }
        }
    }
    
    queryParams() {
        let defaultQuery = '';
        const defaults = Object.keys(new ServerPagination());
        const keys = [...new Set(Object.keys(this).concat(defaults))];
        keys.forEach((key) => {
            if (this[key] !== null && this[key] !== undefined && this[key] !=='' && !isObject(this[key])) defaultQuery += key + '=' + this[key] + '&';
        });
        defaultQuery = defaultQuery.substr(0, defaultQuery.length - 1);
        return defaultQuery;
    }
}

