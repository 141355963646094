import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationRules } from '~shared/helpers';
import { BaseAddComponent } from '~shared/helpers/classes/base-component/base-add-component';
import { SpecialityModel } from '~shared/services/speciality/models';
import { SpecialityService } from '~shared/services/speciality/speciality.service';

const fields = {
  'nume': { rules: ValidationRules.empty },
  'cod': { rules: ValidationRules.empty }
};

@Component({
  selector: 'app-add-speciality',
  templateUrl: './add-speciality.component.html',
  styleUrls: ['./add-speciality.component.scss']
})
export class AddSpecialityComponent extends BaseAddComponent<SpecialityModel> implements OnInit {

  override redirectUrl = '/manage-speciality';

  constructor(service: SpecialityService, router: Router, route: ActivatedRoute) {
    super(service, SpecialityModel, fields, 'specializarea', route, router, true);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }
  }