export class UserModel {
    public id: number = null;

    public fullName: string = null;
    public firstName: string = null;
    public lastName: string = null;

    public email: string = null;
    public roleId: number = null;
    public password: string | null = null;
    public confirmPassword: string | null = null;
}
