import { Component, Input, OnDestroy } from '@angular/core';
import { BaseComponent } from '~shared/helpers/classes/base-component/base-component';
import { Column } from '~shared/helpers/classes/pagination/pagination';
import { ActivatedRoute } from '@angular/router';
import { ArtistService } from '~shared/services/artist/artist.service';
import { BranchHistoryModel } from '~shared/services/branch-history/model';
import { BranchHistoryService } from '~shared/services/branch-history/branch-history.service';

const columns = [
  new Column('oldBranch', 'Filiala de la care a plecat'),
  new Column('newBranch', 'Filiala nouă'),
  new Column('dateTransfer', 'Data de transfer'),
];

@Component({
  selector: 'app-manage-branch-history',
  templateUrl: './manage-branch-history.component.html',
  styleUrls: ['./manage-branch-history.component.scss']
})
export class ManageBranchHistoryComponent extends BaseComponent<BranchHistoryModel> implements OnDestroy {
  @Input() selectedArtistId: number;

  override columns = columns;
  transfer: BranchHistoryModel[] = [];

  constructor(service: BranchHistoryService, private route: ActivatedRoute, private artistService: ArtistService) {
    super(service, 'transfer');
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.loading = true;
    this.service.getBranchHistory(this.selectedArtistId).subscribe(
      (data) => {
        this.transfer = data.map((item) => ({...item}));
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.error = true;
      }
    );
  }
}
