import { Component, Input, AfterViewInit, ElementRef, Output, OnDestroy, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

// import 'semantic-ui-less/definitions/modules/modal';
// import 'semantic-ui-less/definitions/modules/dimmer';
import { uniqueIdentifier } from '../../helpers/global.func';
declare var $: any;

export class CallbackEvent {
    constructor(public type: 'onShow' | 'onVisible' | 'onHide' | 'onHidden' | 'onApprove' | 'onDeny', data?: any) { }
}

@Component({
    selector: 'fm-modal',
    templateUrl: 'modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class FMModalComponent implements AfterViewInit, OnDestroy, OnChanges {

    loading: boolean = false;

    @Input() open: boolean = false;
    @Output() openChange = new EventEmitter<boolean>();

    @Output() callback = new EventEmitter<CallbackEvent>();

    @Input() identifier: string = uniqueIdentifier();

    @Input() hideActions: boolean = false;
    @Input() hideCloseBtn: boolean = false;

    @Input() style: string = '';
    // for more settings and details : https://fomantic-ui.com/modules/modal.html#/settings
    private options = {
        // If set to false will prevent the modal from being moved to inside the dimmer
        detachable: true,
        // When true, the first form input inside the modal will receive focus when shown. Set this to false to prevent this behavior.
        autofocus: true,
        // Whether any change in modal DOM should automatically refresh cached positions
        observeChanges: false,
        // If set to true will not close other visible modals when opening a new one
        allowMultiple: false,
        // Whether to automatically bind keyboard shortcuts
        keyboardShortcuts: true,
        // A vertical offset to allow for content outside of modal, for example a close button, to be centered.
        offset: 0,
        // Selector or jquery object specifying the area to dim
        context: 'body',
        // Setting to false will not allow you to close the modal by clicking on the dimmer
        closable: false,
        // You can specify custom settings to extend UI dimmer
        dimmerSettings: {
            closable: false,
            useCSS: true
        },
        // Named transition to use when animating menu in and out, full list can be found in ui transitions docs.
        transition: 'scale',
        // Duration of animation
        duration: 400,
        // Whether additional animations should queue
        queue: false,

        // Callbacks ----------------------
        // Is called when a modal starts to show.
        // onShow: this._onShow.bind(this),
        // Is called after a modal has finished showing animating.
        onVisible: this._onVisible.bind(this),
        // Is called after a modal starts to hide. If the function returns false, the modal will not hide - onHide($element)
        // onHide: this._onHide.bind(this),
        // Is called after a modal has finished hiding animation
        onHidden: this._onHidden.bind(this),
        // Is called after a positive, approve or ok button is pressed. If the function returns false, the modal will not hide. - onApprove($element)
        onApprove: this._onApprove.bind(this),
        // Is called after a negative, deny or cancel button is pressed. If the function returns false the modal will not hide. - onDeny($element)
        onDeny: this._onDeny.bind(this)
    };

    private selector: string = '.ui.modal.';

    constructor(public element: ElementRef) { }

    ngAfterViewInit() {
        this.selector = this.selector + this.identifier;
        ($(this.selector) as any).modal(this.options);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.open && changes.open.currentValue) ($(this.selector) as any).modal('show');
    }

    ngOnDestroy() {
        // Remove modal's instance
        const modal = $(this.selector);
        if (modal.length) modal.remove();
    }

    private _onVisible() {
        this.callback.emit(new CallbackEvent('onVisible'));
    }

    private _onHidden() {
        this.openChange.emit(false);
        this.callback.emit(new CallbackEvent('onHidden'));
    }

    private _onApprove($element) {
        this.openChange.emit(false);
        this.callback.emit(new CallbackEvent('onApprove'));
    }

    private _onDeny($element) {
        this.openChange.emit(false);
        this.callback.emit(new CallbackEvent('onDeny'));
    }

}
