import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { handleSwaggerError } from '~shared/helpers';
import { BaseComponent } from '~shared/helpers/classes/base-component/base-component';
import { Column, Pagination } from '~shared/helpers/classes/pagination/pagination';
import { ArtistPaymentService } from '~shared/services/artist-payment/artist-payment.service';
import { ArtistPaymentModel } from '~shared/services/artist-payment/models';
import { ArtistService } from '~shared/services/artist/artist.service';
import { ArtistModel } from '~shared/services/artist/models';
import { MemberShortModel } from '~shared/services/member/models/models';
import { PaymentTypeService } from '~shared/services/payment-type/payment-type.service';
import { RecipientTypeService } from '~shared/services/recipient-type/recipient-type.service';

const columns = [
  new Column('name', 'Nume'),
  new Column('amount', 'Suma platita'),
  new Column('paymentTypeId', 'Tipul platii'),
  new Column('recipientTypeId', 'Beneficiar')
];

@Component({
  selector: 'manage-payment',
  templateUrl: './manage-payment.component.html',
  styleUrls: ['./manage-payment.component.scss']
})
export class ManagePaymentComponent extends BaseComponent<ArtistPaymentModel> implements OnDestroy {

  artistPayment = new ArtistPaymentModel();
  currentUser: MemberShortModel = new MemberShortModel();

  artist: ArtistModel = null;
  currentArtistId = 0;

  recipientTypes = [];
  paymentsType = [];



  override columns = columns;

  constructor(service: ArtistPaymentService, private route: ActivatedRoute, private artistService: ArtistService, private paymentTypeService: PaymentTypeService,
    private recipientTypeService: RecipientTypeService) {
    super(service, 'plata', true);
    this.route.params.subscribe((params) => {
      if (params.artistId) this.currentArtistId = Number(params.artistId);

      this.pagination.artistId = this.currentArtistId;
      this.fetchData(this.currentArtistId);
    })
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  download(payment: ArtistPaymentModel) {
    this.downloadBase64File('application/pdf', payment.payment, payment.name);
  }

  onSaveSuccess = () => {
    this.getData();
  }

  setLoading(isLoading) {
    this.loading = isLoading;
  }

  fetchData(event: number) {
    this.getArtistData(event);
    this.getPaymentType();
    this.getRecipientType();

    this.pagination.artistId = event;
    this.getData(false);
  }

  matchPaymentType(type: number) {
    return this.paymentsType.find((item) => item.id == type)?.name;
  }

  matchRecepientType(type: number) {
    return this.recipientTypes.find((item) => item.id == type)?.name;
  }

  private downloadBase64File(contentType, base64Data, fileName) {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  private getArtistData(artistId) {
    this.artistService.getSingle(artistId).subscribe({
      next: (response) => {
        this.artist = response;
      },
      error: handleSwaggerError.bind(this)
    })
  }

  private getPaymentType() {
    this.paymentTypeService.get(new Pagination(() => { }, 1, 10000)).subscribe({
      next: (response) => {
        this.paymentsType = response;
      },
      error: handleSwaggerError.bind(this)
    })
  }

  private getRecipientType() {
    this.recipientTypeService.get(new Pagination(() => { }, 1, 10000)).subscribe({
      next: (response) => {
        this.recipientTypes = response;
      },
      error: handleSwaggerError.bind(this)
    })
  }
}
