import { Component } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { AppNotificationService, IAppNotificationSubscription } from '~shared/services';
import { AuthService } from '~shared/services/auth/auth.service';
import { MemberShortModel } from '~shared/services/member/models/models';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  activeTab = 1;

  user: MemberShortModel;

  constructor(private auth: AuthService, private appNotification: AppNotificationService) {
    this.getCurrentUser();
    this.appNotification.keepUpdated('updateProfile', <IAppNotificationSubscription>{
      component: 'ProfileComponent', callback: (response) => {
        this.getCurrentUser();
      }
    });
  }

  private getCurrentUser() {
  this.auth.getCurrentUser().subscribe((u) => this.user = cloneDeep(u));
  }
}
