import { Component, AfterViewInit, OnInit } from '@angular/core';
import { checkForm, handleSwaggerError } from 'src/shared/helpers/global.func';
import { AuthNames, ValidationRules } from '~shared/helpers';
import { AuthService } from '~shared/services/auth/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements AfterViewInit {
    email: string;
    password: string;

    showPassword = false;

    loading: boolean;
    saving: boolean;

    errors: string[] = [];
    authForgot = '/' + AuthNames.prefix + '/' + AuthNames.forgot;
    register = '/' + AuthNames.prefix + '/' + AuthNames.register;


    constructor(private authService: AuthService) {}   

    ngAfterViewInit() {
        this.applyFormValidation();
    }

    logIn() {
        if (!checkForm()) return;
        this.loading = true;
        const model ={ email: this.email, password: this.password };
        this.authService.login(model).subscribe(response => {
            this.authService.successAuth(response);
            this.loading = false;
        }, handleSwaggerError.bind(this, [{setAlert: true}]));
        return;
    }

    // form validation
    applyFormValidation() {

        ($('.ui.form') as any)
            .form({
                fields: {
                    username: {
                        rules: ValidationRules.common
                    },
                    password: {
                        rules: ValidationRules.password
                    }
                },
                inline: true,
                on: 'blur'
            });
    }
}

