import { Column, Pagination } from '../pagination/pagination';
import { AuthService } from '~shared/services/auth/auth.service';
import { Subject } from 'rxjs';
import { AppInjector, DateFormat, EMPTY_GUID, trackBy } from '~shared/helpers';
import { Directive, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { handleSwaggerError, setAlert } from '~shared/helpers/global.func';
import { ResourceType } from '~shared/services';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { each } from 'lodash-es';
import * as moment from 'moment-mini';

@Directive()
export class BaseComponent<T> implements OnDestroy {
  loading = true;
  error = false;
  errors = [];

  columns: Column[] = [];

  data: Array<T> = new Array<T>();
  refresh: () => void;
  pagination: Pagination;
  user: any;
  authService: AuthService;
  resourceType = ResourceType;
  dateFormat = DateFormat;

  trackBy: (index: number, item: any) => number | string;

  currentDate = moment();

  protected ngUnsubscribe: Subject<any> = new Subject();

  constructor(protected service: any, private entityName: string = null, private skipRequest = false) {

    this.pagination = new Pagination(this.getData.bind(this, skipRequest));
    this.refresh = this.getData.bind(this, true);
    //if (!skipRequest) this.refresh();

    const injector = AppInjector();
    this.authService = injector.get(AuthService);

    this.authService.getCurrentUser().subscribe((user) => {
      this.user = user;
    });

    this.trackBy = trackBy.bind(this);

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  applySort(onColumn: Column) {
    if(!onColumn.isSortable) return;
    // remove sort from the other columns
    each(this.columns, (column: Column, key: number) => {
      if (column.property === onColumn.property) return;
      column.sortAsc = false;
      column.sortDesc = false;
    });

    // manage column's sorting
    if (onColumn.sortDesc) {
      onColumn.sortDesc = false;
      onColumn.sortAsc = true;
    } else if (onColumn.sortAsc) {
      onColumn.sortAsc = false;
      onColumn.sortDesc = true;
    } else if (!onColumn.sortAsc) {
      onColumn.sortAsc = true;
      onColumn.sortDesc = false;
    } else {
      onColumn.sortAsc = false;
      onColumn.sortDesc = true;
    }

    this.pagination.orderField = onColumn.property + ' ' + (onColumn.sortAsc ? 'asc' : 'desc');
    this.pagination.changePage(1);
  }

  delete(id: number) {
    if (!id) return;

    this.loading = true;

    Swal.fire({
      title: `Ești sigur că vrei să ștergi ${this.entityName}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Da',
      cancelButtonText: 'Nu',
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.delete(id).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            () => {
              this.loading = false;
              this.getData(false);
              setAlert('success', "Ștergerea a fost efectuată cu succes");
            }, handleSwaggerError.bind(this, [{ setAlert: true, skipErrors: true }])
          );
      }
      else {
        this.loading = false;
      }
    })
  }

  sendNotification(id: string, index: number) {

    (this.data[index] as any).disabled = true;

    Swal.fire({
      title: `Ești sigur că vrei să trimiți notificare pentru ${this.entityName} acesta?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Da',
      cancelButtonText: 'Nu',
    }).then((result) => {
      if (result.isConfirmed) {
        setTimeout(() => {
          (this.data[index] as any).disabled = false;
        }, 30000);
        this.service.sendNotification(id).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            () => {
              this.loading = false;
              setAlert('success', "Notificarea a fost trimisă cu succes");
            }, handleSwaggerError.bind(this, [{ setAlert: true }])
          );
      }
      else {
        this.loading = false;
        (this.data[index] as any).disabled = false;

      }
    })
  }  

  compareDate(publishDate: Date){
    if(this.currentDate.isAfter(moment(publishDate))) return false;
    else return true;
  }

  protected getData(skipRequest = false) {
    if(skipRequest) return;
    this.error = false;
    this.loading = true;
    this.data = [];
    

    this.service.get(this.pagination)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response: T[]) => {
          this.loading = false;
          this.data = response;
        }, handleSwaggerError.bind(this, [{ setAlert: true }])
      );
  }
}