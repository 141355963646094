import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AuthNames, checkForm, handleSwaggerError, ValidationRules } from '~shared/helpers';
import { AuthService } from '~shared/services/auth/auth.service';
import { ActivateModel } from '~shared/services/auth/models';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit, AfterViewInit {

  model = new ActivateModel();
  loading = false;
  errors = [];
  loginLink = '/' + AuthNames.prefix + '/' + AuthNames.login;
  
  constructor(private route: ActivatedRoute, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params)=>{
      if (params.SecurityCode) this.model.securityCode = params.SecurityCode;
    });
  }
  ngAfterViewInit(): void {
    this.applyFormValidation();
  }

  activate(){
    if (!checkForm()) return;
    this.loading = true;
    this.errors = [];
    
    this.authService.activate(this.model).subscribe(()=>{
      this.router.navigateByUrl(this.loginLink);
    }, handleSwaggerError.bind(this, [{ setAlert: false }]))
  }

  // form validation
  private applyFormValidation() {

    setTimeout(() => {
      ($('.ui.form') as any)
        .form({
          fields: {
            securityCode: {
              rules: ValidationRules.empty
            },
          },
          inline: true,
          on: 'blur'
        });
    }, 10);
  }

}
