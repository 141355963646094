import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

import { Router } from '@angular/router';
import { asObservable } from 'src/shared/helpers/global.func';
import { cloneDeep, filter, find, includes, remove } from 'lodash-es';
import { IRouteChildComponent } from '~shared/helpers/global.class';
import { HttpClient } from '@angular/common/http';
import { Role, LocalStorageKeys, AuthNames, Routes, EMPTY_GUID } from '~shared/helpers';
import { environment } from 'src/environments/environment';
import { ActivateModel, RegisterModel } from './models';
import { share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {

    private user: any;
    url = environment.apiUrl + 'user';
    private currentUser$: Observable<any> | undefined;

    constructor(private router: Router, private http: HttpClient) {
    }


    get currentUser() {
        return this.user;
    }


    isLoggedIn() {
        return this.currentUser;
    }

    get isAdmin() {
        return this.user ? this.user.isAdmin : false;
    }

    getCurrentUser(skipRequest = true): Observable<any> {
        if (this.user && skipRequest) return asObservable(this.user);

        return this.http.get<any>(this.url + '/member', {}).pipe(map(r => {
            this.user = r;
            this.user.isAdmin = this.user.roleId === Role.Admin;
            return r;
        }));
    }

    getToken() {
        if (!localStorage.getItem(LocalStorageKeys.token)) return null;
        return localStorage.getItem(LocalStorageKeys.token);
    }

    decodeToken(): any {
        const token = this.getToken();
        if (token && token != '') {
            try {
                return jwt_decode(token);
            } catch (exception) {
                console.error(exception);
            }
        }
        return null;
    }

    checkToken() {
        const token = this.decodeToken() as any;
        if (token) {
            const todayDate = new Date();
            const expires = new Date(token.exp * 1000);
            if (expires < todayDate) {
                this.logout();
                return false;
            }
            return true;

        } else {
            this.logout();
            return false;
        }

    }

    successAuth(response) {
        if (response) {
            this.redirectToSpecificUrl();
        }
    }

    redirectToSpecificUrl() {
        this.getCurrentUser().subscribe((user) => {
            if (!this.user) {
                this.router.navigateByUrl('/');
                return false;
            }

            this.provideSidebarRoutes();
            const redirectTo = this.provideFirstAccessibleRoute();

            if (redirectTo) this.router.navigateByUrl(this.provideFirstAccessibleRoute())
            else this.logout();
        });
    }


    login(model): Observable<any> {
        return this.http.post<any>(this.url + '/login', model)
    }

    logout(fromApp = false) {
        this.http.get<any>(this.url + '/log-out').subscribe(() => {
            this.user = null;
            this.router.navigateByUrl(fromApp ? (AuthNames.prefix + '/' + AuthNames.login) : '/');
        });
    }

    gotoLogin() {
        this.user = null;
        this.router.navigateByUrl(AuthNames.prefix + '/' + AuthNames.login);
    }

    register(model: RegisterModel) {
      console.log('r form service')
        return this.http.post<any>(this.url + '/register', model)
    }

    activate(model: ActivateModel) {
        return this.http.post<any>(this.url + '/activate', model)
    }

    provideSidebarRoutes() {
        let routes = [];

        Routes.forEach((item, index) => {
            let children = filter(item.children, (r: IRouteChildComponent) => {
                return includes(r.access, this.user.roleId);
            });

            if (children.length) {
                item.children = children;
                routes.push(item);
            }
        });

        return routes;
    }

    onAppInit(): Observable<any> {
        if (window.location.pathname === '/' + AuthNames.prefix + '/' + AuthNames.activate ||
            window.location.pathname === '/' + AuthNames.prefix + '/' + AuthNames.register) return of(true);
        this.getCurrentUser().subscribe((user) => {
            if (user && (
                window.location.pathname === '/' ||
                window.location.pathname === '/' + AuthNames.prefix + '/' + AuthNames.login ||
                window.location.pathname === '/' + AuthNames.prefix + '/' + AuthNames.forgot ||
                window.location.pathname === '/' + AuthNames.prefix + '/' + AuthNames.register ||
                window.location.pathname === '/' + AuthNames.prefix + '/' + AuthNames.activate)) {
                this.redirectToSpecificUrl();
            }
        })
        return of(true);

    }

    private provideFirstAccessibleRoute() {
        const routes = cloneDeep(Routes);
        let route = '';

        find(routes, (item) => {
            if (route) return true;
            find(item.children, (r: IRouteChildComponent) => {
                if (includes(r.access, this.user.roleId)) {
                    route = r.route;
                    return true;
                }
            });
        });

        return route;
    }

}
