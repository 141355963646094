<form class="ui form create event" autocomplete="off" *ngIf="!loading" id="{{formId}}">
  <div class="ui five fields">
    <div class="field required">
      <label>Total plătit</label>
      <input type="number" name="amount" placeholder="Total plătit" [(ngModel)]="artistPayment.amount"
        (change)="onDropDownChange()">
    </div>


    <div class="field required">
      <label>Tip plata</label>
      <fm-dropdown [list]="paymentsType" [placeholder]="'Tip Plata'" [(optionId)]="artistPayment.paymentTypeId"
        (optionIdChange)="onDropDownChange()">
      </fm-dropdown>
      <input type="hidden" name="paymentType" [(ngModel)]="artistPayment.paymentTypeId" />
    </div>
    <div class="field required">
      <label>Beneficiar</label>
      <fm-dropdown [list]="recipientTypes" [placeholder]="'Beneficiar'" [(optionId)]="artistPayment.recipientTypeId"
        (optionIdChange)="onDropDownChange()">
      </fm-dropdown>
      <input type="hidden" name="recipientType" [(ngModel)]="artistPayment.recipientTypeId" />

    </div>
    <div class="field required">
      <label>Months</label>
      <fm-dropdown [list]="months" [placeholder]="'Luna'" [(optionId)]="artistPayment.month"
        (optionIdChange)="onDropDownChange()">
      </fm-dropdown>
      <input type="hidden" name="month" [(ngModel)]="artistPayment.month" />

    </div>
    <div class="field required">
      <label>An</label>
      <input type="number" name="year" placeholder="An" [(ngModel)]="artistPayment.year" (change)="onDropDownChange()">
    </div>
  </div>
  <div class="field">
    <label>Detalii plata</label>
    <input type="text" name="details" placeholder="Detalii plata" [(ngModel)]="artistPayment.details">
  </div>
  <div class="ui field">
    <label>Observații</label>
    <textarea [(ngModel)]="artistPayment.observations" name="obs"></textarea>
  </div>
  <div class="ui field file-upload" *ngIf="artistPayment">
    <button class="ui mini normal button" type="button" (click)="docFile.click()">
      <i class="upload icon"></i>
      Adaugă document
    </button>
    <span class="middle">{{file?.name}} <a *ngIf="file" class="delete-file middle" (click)="onRemoveFile()"><i
          class="icon delete"></i></a></span>


    <input #docFile class="file-input" type="file" name="doc" accept="{{acceptedDocType}}"
      (change)="prepareFile($event.target.files[0])" />
  </div>

  <button class="ui mini blue right floated button" (click)="save()" [ngClass]="{loading: loading}"
    [disabled]="isSaveDisabled">
    <i class="save icon"></i> <span>Salvează</span>
  </button>
</form>
