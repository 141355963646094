import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '~shared/helpers/classes/base-component/base-component';
import { Column } from '~shared/helpers/classes/pagination/pagination';
import { StatuteModel } from '~shared/services/statute/models';
import { StatuteService } from '~shared/services/statute/statute.service';

const columns=[
  new Column('name', 'Nume'),
  new Column('code', 'Cod')
];

@Component({
  selector: 'app-manage-statute',
  templateUrl: './manage-statute.component.html',
  styleUrls: ['./manage-statute.component.scss']
})
export class ManageStatuteComponent extends BaseComponent<StatuteModel> implements OnDestroy {
  override columns = columns;

  constructor(service: StatuteService) {
    super(service, 'statut');
  }

  exportExcel() {
    this.service.downloadFile();
  }
  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
