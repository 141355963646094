import { Component, Input, ElementRef, OnChanges, SimpleChanges, SimpleChange, NgZone, Output, EventEmitter, AfterContentInit } from '@angular/core';
import { isString } from 'lodash-es';

declare var $: any;

import * as moment from 'moment-mini';

import 'semantic-ui-calendar/src/definitions/modules/calendar';
import { DateFormatInput } from '~shared/helpers';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'fm-datepicker',
    template: `
    <div class="ui calendar" [ngClass]="{'disabled' : isDisabled}">
      <div class="ui fluid input right icon">
        <i class="calendar icon"></i>
        <input type="text" [attr.placeholder]="placeholder" readonly/>
      </div>
    </div>
  `,
    styles: ['']
})
export class DatepickerComponent implements AfterContentInit, OnChanges {
    @Input() placeholder = 'Date';

    // To output change as a method
    @Output('change') changeEmitter: EventEmitter<string> = new EventEmitter<string>();

    @Output('modelChange') modelChangeEmitter: EventEmitter<string> = new EventEmitter<string>();

    @Input() minDate: string;
    @Input() maxDate: string;
    @Input() isMaxDateToday = false;
    @Input() isMinDateToday = false;
    @Input() model: string | Date;
    @Input() isDisabled: boolean = false;
    @Input() type: string = 'date';
    @Input() required = false;

    private _options = {
        type: this.type, // picker type, can be 'datetime', 'date', 'time', 'month', or 'year'
        firstDayOfWeek: 0, // day for first day column (0 = Sunday)
        constantHeight: true, // add rows to shorter months to keep day calendar height consistent (6 rows)
        today: false, // show a 'today/now' button at the bottom of the calendar
        closable: true, // close the popup after selecting a date/time
        monthFirst: true, // month before day when parsing/converting date from/to text
        touchReadonly: true, // set input to readonly on touch devices
        inline: false, // create the calendar inline instead of inside a popup
        on: null, // when to show the popup (defaults to 'focus' for input, 'click' for others)
        initialDate: null, // date to display initially when no date is selected (null = now)
        startMode: false, // display mode to start in, can be 'year', 'month', 'day', 'hour', 'minute' (false = 'day')
        minDate: null, // minimum date/time that can be selected, dates/times before are disabled
        maxDate: null, // maximum date/time that can be selected, dates/times after are disabled
        ampm: false, // show am/pm in time mode
        disableYear: false, // disable year selection mode
        disableMonth: false, // disable month selection mode
        disableMinute: false, // disable minute selection mode
        formatInput: true, // format the input text upon input blur and module creation
        startCalendar: null, // jquery object or selector for another calendar that represents the start date of a date range
        endCalendar: null, // jquery object or selector for another calendar that represents the end date of a date range
        multiMonth: 1, // show multiple months when in 'day' mode

        formatter: {
            date: this._formatDate.bind(this)
        },

        // callback when date changes, return false to cancel the change
        onChange: this._onChange.bind(this)
    };

    private _datepicker;
    private _format = DateFormatInput;

    constructor(public element: ElementRef, private _zone: NgZone) { }

    ngAfterContentInit() {
        // delay to avoid expression changed error
        this._datepicker = $(this.element.nativeElement.children[0]) as any;        

        this._options.maxDate = this._checkDate(this.maxDate);
        this._options.minDate = this._checkDate(this.minDate);
        this._options.initialDate = this._checkDate(this.model);
        this._options.type = this.type;

        if (this.isMaxDateToday && !this.isMinDateToday) {
            this._options.maxDate = moment().toDate();
        }
        if (this.isMinDateToday && !this.isMaxDateToday) {
            this._options.minDate = moment().toDate();
        }

        this._datepicker.calendar(this._options);
        // console.log('options:', this._options);
        if (this.model) {
            this._datepicker.calendar('set date', this._options.initialDate, true, false);
        }
    }

    private _checkDate(date) {
        return date ? (isString(date) ? moment(date).toDate() : date) : null;
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log('In on changes with changes:', changes);
        if (!this._datepicker) {
            return;
        }

        const minDate: SimpleChange = changes.minDate;
        if (minDate) {
            if (minDate.currentValue !== minDate.previousValue) {
                let min = this.isMinDateToday ? moment().toDate() : null;
                if (minDate.currentValue) {
                    min = moment(minDate.currentValue, this._format).toDate();
                    if (!moment(min).isValid()) min = moment(minDate.currentValue).toDate();
                }
                setTimeout(() => {
                    this._datepicker.calendar('setting', 'minDate', min);
                }, 0);
            }
        }

        const maxDate: SimpleChange = changes.maxDate;
        if (maxDate) {
            if (maxDate.currentValue !== maxDate.previousValue) {
                let max = this.isMaxDateToday ? moment().toDate() : null;
                if (maxDate.currentValue) {
                    max = moment(maxDate.currentValue, this._format).toDate();
                    if (!moment(max).isValid()) max = moment(maxDate.currentValue).toDate();
                }
                setTimeout(() => {
                    this._datepicker.calendar('setting', 'maxDate', max);
                }, 0);
            }
        }

        const model: SimpleChange = changes.model;
        if (model) {
            if (model.currentValue !== model.previousValue && !model.previousValue) {
                const date = model.currentValue ? moment(model.currentValue).toDate() : null;
                setTimeout(() => {
                    this._datepicker.calendar('set date', date);
                }, 0);
            }
        }
    }

    private _formatDate(date: string, settings: any) {
        const momentDate = moment(date);
        // console.log('Format:', momentDate.format(this._format));
        return momentDate.format(this._format);
    }

    private _onChange(date: Date, text: string) {
        if (!date) return;
        this.changeEmitter.emit(text);
        this.modelChangeEmitter.emit(moment(date).toISOString());
        // console.log('On change:', date);
    }
}
