import { Component, Injector, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AppInjector } from 'src/shared/helpers';
import { SplashScreenService } from '~shared/components/layout/splash-screen/splash-screen.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private injector: Injector, private splashScreenService: SplashScreenService, private router: Router) {
    AppInjector(this.injector);
    ($.fn as any).form.settings.onSuccess = () => false;

  }

  ngOnInit() {
    if (window.location.pathname === '/') setTimeout(() => {
      this.splashScreenService.hide();
    }, 10);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

      }
    });
  }

}
