import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AuthService } from '~shared/services/auth/auth.service';
import { AppInjector, handleSwaggerError, matchRole, Role, setAlert } from '~shared/helpers';
import { BaseComponent } from '~shared/helpers/classes/base-component/base-component';
import { Column } from '~shared/helpers/classes/pagination/pagination';
import { IResource, Resource, ResourceService, ResourceType } from '~shared/services';
import { MemberService } from '~shared/services/member/member.service';
import { MemberShortModel } from '~shared/services/member/models/models';

const columns = [
  new Column('firstName', 'Prenume'),
  new Column('LastName', 'Nume'),
  new Column('email', 'Email'),
  new Column('role', 'Role')
];

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent extends BaseComponent<MemberShortModel> implements AfterViewInit, OnDestroy {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  override columns = columns;
  matchRole: Function;

  private resource: ResourceService;
  private auth: AuthService;

  roles: IResource[];

  override resourceType = ResourceType;

  constructor(memberService: MemberService) {
    super(memberService, 'userul');

    const injector = AppInjector();
    this.resource = injector.get(ResourceService);
    this.auth = injector.get(AuthService);

    this.resource.getResource(ResourceType.Roles).subscribe((roles) => {
      this.roles = cloneDeep(roles);
    })

    this.matchRole = matchRole.bind(this);
  }

  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => event.target.value),
        // Time in milliseconds between key events
        debounceTime(500),
        // If previous query is diffent from current
        distinctUntilChanged(),
        // subscription for response
        takeUntil(this.ngUnsubscribe)
      ).subscribe((text) => {
        this.pagination.searchText = text;
        this.getData();
      });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  exportMembers() {
    this.service.exportMembers();
  }

  onRoleSelection() {
    this.getData();
  }

  resetFilters() {
    this.pagination.roleId = null;
  }
}
