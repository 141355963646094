import { Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppInjector, transformKeys } from '../helpers';
import { Pagination, ServerPagination } from '~shared/helpers/classes/pagination/pagination';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class BaseService {
  url: string;
  protected http: HttpClient;

  constructor(controller: string) {
    this.url = environment.apiUrl + controller;
    const injector: Injector = AppInjector(); // get the stored reference to the injector
    this.http = injector.get(HttpClient);
  }

  get(pagination: Pagination): Observable<Array<any>> {
    const params = new HttpParams({ fromString: (new ServerPagination(pagination).queryParams()) });
    return this.http.get(this.url, { params, observe: 'response' }).pipe(map((response) => {
      pagination.totalNumberOfItems = JSON.parse(response.headers.get('X-Pagination')).TotalCount;
      return response.body as Array<any> || [];
    }));
  }

  getSingle(id: number): Observable<any> {
    return this.http.get<any>(this.url + '/' + id);
  }

  save(data: any) {
    const method = data.id ? 'put' : 'post';
    const url = this.url + (data.id ? '/' + data.id : '');

    const model = transformKeys(data, false);
    return this.http[method](url, model);
  }

  patch(data:any){
    const model = transformKeys(data, false);
    return this.http.patch(this.url + '/' + data.id, model);
  }


  delete(id: string) {
    return this.http.delete(this.url + '/' + id);
  }

  saveQueue(data: any) {

    const model = transformKeys(data, false);
    return this.http.post(this.url, model);
  }

  sendNotification(id: string) {
    return this.http.post(this.url + '/notification/' + id, null);
  }
}
