<div class="ui vertical footer segment">
    <div class="ui horizontal small link list">
        <a class="item" (click)="openModal(termsIdentifier)">Termeni și condiții</a>
        <a class="item" (click)="openModal(privacyPolicyIdentfier)">Politica de protejare a datelor</a>
        <a class="item"> Copyright 2022 Catalin Iordache & Cosmin Mihale. All rights reserved.
        </a>
    </div>
</div>

<fm-modal [identifier]="termsIdentifier" [hideActions]="true" (callback)="termsCallback($event)"
    [style]="'overlay fullscreen'">
    <div class="modal-title">
        Termeni și condiții
    </div>
    <div class="modal-content">
        <div [innerHTML]="termsAndConditions"></div>
    </div>
</fm-modal>

<fm-modal [identifier]="privacyPolicyIdentfier" [hideActions]="true" [style]="'overlay fullscreen'">
    <div class="modal-title">
        Privacy Policy
    </div>
    <div class="modal-content">
        <div [innerHTML]="privacyPolicy"></div>
    </div>
</fm-modal>