import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationRules } from '~shared/helpers';
import { BaseAddComponent } from '~shared/helpers/classes/base-component/base-add-component';
import { BranchService } from '~shared/services/branch/branch.service';
import { BranchModel } from '~shared/services/branch/models';
const fields = {
  'nume': { rules: ValidationRules.empty },
  'cod': { rules: ValidationRules.empty }
};

@Component({
  selector: 'app-add-branch',
  templateUrl: './add-branch.component.html',
  styleUrls: ['./add-branch.component.scss']
})
export class AddBranchComponent extends BaseAddComponent<BranchModel> implements OnInit {

  override redirectUrl = '/manage-branch';

  constructor(service: BranchService, router: Router, route: ActivatedRoute) {
    super(service, BranchModel, fields, 'Filiala ul', route, router, true);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }
}
