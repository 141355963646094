import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from 'src/shared/shared.module';
import { AuthNames } from '~shared/helpers/global.const';
import { AuthComponent } from './auth.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ConfirmAccountComponent } from './confirm-account/confirm-account.component';

const routes: Routes = [
  {
    path: AuthNames.prefix, component: AuthComponent,
    children: [
      { path: AuthNames.login, component: LoginComponent},
      { path: AuthNames.forgot, component: ForgotPasswordComponent },
      { path: AuthNames.register, component: RegisterComponent },
      { path: AuthNames.activate, component: ConfirmAccountComponent },
      { path: '**', redirectTo: 'not-found', pathMatch: 'full' }

    ]
  }
];

const declarations = [LoginComponent, ForgotPasswordComponent, RegisterComponent, ConfirmAccountComponent];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations,
  exports: declarations
})
export class AuthModule { }
