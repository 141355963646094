export class ArtistPaymentModel {
  id: number;
  name: string;
  amount: number;
  payment: string;
  artistId: number;
  details: string;
  observations: string;
  month: number;
  year: number;
  paymentTypeId: number;
  recipientTypeId: number;

  debtId: number;

}
