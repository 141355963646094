import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// MODULES --------------------------------------------------------
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module

// COMPONENTS ----------------------------------------------------
import { NoDataComponent, InvalidRequestComponent, LoaderComponent } from './components';
import { FMModalComponent, FMTooltipDirective } from './fomantic';
import { FMDropdownMultipleComponent } from './fomantic/dropdown/dropdown-multiple.component';
import { FMDropdownComponent } from './fomantic/dropdown/dropdown.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { SideBarComponent } from './components/layout/sidebar/sidebar.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { ToggleComponent } from './fomantic/toggle/toggle.component';
import { RouterModule } from '@angular/router';
import { DatepickerComponent } from './fomantic/datepicker/datepicker.component';
import { FirstLetterPipe } from './pipes/first-letter/first-letter.pipe';
import { SettingsComponent } from './components/profile/settings/settings.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ChangePasswordComponent } from './components/profile/change-password/change-password.component';
import { SplashScreenComponent } from './components/layout/splash-screen/splash-screen.component';
import { ManageBranchHistoryComponent } from './components/manage-branch-history/manage-branch-history.component';
import { ManageSpecialityHistoryComponent } from './components/manage-speciality-history/manage-speciality-history.component';
import { ManageStatutHistoryComponent } from './components/manage-statut-history/manage-statut-history.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FMSearchComponent } from './fomantic/search/search.component';
// ---------------------------------------------------------------
const directives: any[] = [];
const fomantic: any[] = [FMDropdownComponent, FMDropdownMultipleComponent, FMModalComponent, FMTooltipDirective, DatepickerComponent, ToggleComponent, FMSearchComponent];
const declarations: any[] = [NoDataComponent, InvalidRequestComponent, LoaderComponent, FooterComponent,
  SideBarComponent, HeaderComponent, LayoutComponent, FirstLetterPipe, ProfileComponent, SettingsComponent,
  ChangePasswordComponent, SplashScreenComponent, NotFoundComponent].concat(fomantic).concat(directives);
const imports: any[] = [FormsModule, CommonModule, HttpClientModule, NgxPaginationModule, RouterModule];
const exports = declarations.concat(imports);

@NgModule({
  declarations: [
    declarations,
    ManageBranchHistoryComponent,
    ManageSpecialityHistoryComponent,
    ManageStatutHistoryComponent,
    LayoutComponent
  ],
  exports: [
    exports,
    ManageBranchHistoryComponent,
    ManageSpecialityHistoryComponent,
    ManageStatutHistoryComponent,
    LayoutComponent
  ],
  imports,
  providers: []
})
export class SharedModule { }
