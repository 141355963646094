<div class="container">

  <div class="ui grid">
    <div class="row">
      <div class="col">
        <a *ngIf="showBackButton" (click)="goBack()" class="ui right floated">
          <i class="angle left icon"></i>
          Listă artiști
        </a>
      </div>
    </div>
    <div class="row" *ngIf="!showBackButton">
      <div class="sixteen wide tablet four wide computer column">
        <fm-search [type]="resourceType.Artists" [placeholder]="'Caută artist'" [minCharachers]="1"
                   (onChange)="getArtistWithId($event)">
        </fm-search>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="artist;">
    <div class="ten wide computer column">
      <div class="ui grid">
        <div class="five wide column">
          <div class="ui raised segment">
            <a class="ui green ribbon label">Detalii artist</a>
            <div class="labels-info">
              <label><b>Nume: </b> <span>{{artist.lastName}}</span></label>
              <label><b>Prenume: </b> <span>{{artist.firstName}}</span> </label>
              <label><b>Filiala: </b> <span>{{artist.branch.name}}</span></label>
              <label><b>Specializare: </b> <span>{{artist.speciality.name}}</span> </label>
              <label><b>Statut: </b><span>{{artist.statut.name}}</span></label>
              <label><b>An dobândire statut: </b><span>{{artist.dateChanged}}</span></label>
              <label><b>Email: </b> <span>{{artist.email}}</span> </label>
              <label><b>Nr de telefon: </b> <span>{{artist.phoneNumber}}</span> </label>
              <label><b>Adresa: </b> <span>{{artist.address}}</span> </label>
            </div>
          </div>
        </div>
        <div class="sixteen wide column">
          <div class="ui segment">
            <a class="ui orange right ribbon label">Datorii</a>
            <div class="ui grid">
              <div class="row"></div>
              <div class="ui segment payment-table" style="width: 100%;">
                <no-data *ngIf="!debts.length && !loading && !error"></no-data>
                <app-loader *ngIf="loading"></app-loader>
                <invalid-request *ngIf="error" [(refresh)]="refresh"></invalid-request>
                <section [hidden]="loading || !debts.length">
                  <div class="table-container" *ngIf="!error">
                    <table class="ui very basic sortable table">
                      <thead>
                      <tr>
                        <th *ngFor="let column of deptTableColumns" class="sorted">
                          {{column.label}}
                        </th>
                        <td></td>
                      </tr>
                      </thead>
                      <tbody>
                      <ng-container *ngFor="let debt of debts">
                        <tr [hidden]="loading">
                          <td [innerHTML]="debt.observations ??'Nici-o observație'"></td>
                          <td [innerHTML]="debt.amount">{{ totalDept | currency:' RON '}}</td>
                          <td [innerHTML]="debt.remainings">{{ totalDept | currency:' RON '}}</td>
                          <td [innerHTML]="debt.year"></td>
                          <td><i *ngIf="debt.isPaid" class="large green checkmark icon"></i></td>
                          <td><a (click)="deletePayment(debt.id)">
                                <i *ngIf="debt.isPaid" class="close icon" title="Șterge chitanța"></i>
                              </a>
                          </td>
                        </tr>
                      </ng-container>
                      <tr>
                        <td><b>Total</b></td>
                        <td><b> {{ totalRestToPay | currency:' RON '}} </b></td>
                        <td><b> {{ totalDept | currency:' RON '}} </b></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </section>

              </div>
            </div>

          </div>
        </div>
        <div class="sixteen wide column">
          <div class="ui raised segment">
            <a class="ui green ribbon label">Suma de Plată</a>
            <form (submit)="onCalculateDebts()">
              <div class="field required">
                <label>Total plătit : </label>
                <div class="ui action input">
                  <input type="text" type="number" name="amount" placeholder="Total plătit" [(ngModel)]="amountToPay">
                  <button class="ui primary button" type="submit">Plătește</button>
                </div>
              </div>
            </form>
            <div class="labels" *ngIf="amountToPay > 0 && showTable">
              <table class="ui very basic sortable table">
                <thead>
                <tr>
                  <th *ngFor="let column of paymentTableColumns" class="sorted">
                    {{column.label}}
                  </th>
                </tr>
                </thead>
                <tbody>
                <ng-container [hidden]="loading" *ngFor="let debt of debtsAfterCalculation ">
                  <tr>
                    <td [innerHTML]="debt.year"></td>
                    <td [innerHTML]="debt.amount"></td>
                    <td [innerHTML]="debt.paid"></td>
                    <td>
                      <div class="ui mini icon input">
                        <input type="text" class="form-control" (change)="onSearchChange($event.target.value,debt)"
                               placeholder="adăugați observație">
                      </div>
                    </td>
                    <td [innerHTML]="debt.remainingToPay"></td>
                  </tr>
                </ng-container>
                <tr *ngIf="remainingAmountToPay > 0">
                  <td>
                    <b>
                      Rest de înapoiat: {{remainingAmountToPay}}
                    </b>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
              </table>

              <div class="ui divider"></div>

              <div class="payment-buttom-buttons">
                <label>Numărul Chitanță : </label>
                <div class="ui small icon input">
                  <input type="number" name="receiptNumber" placeholder="Introduceți numărul chitanței"
                         (change)="onAddInvoiceNumber($event.target.value)">
                </div>

                <div class="ui divider"></div>

                <div class="payment-buttons" *ngIf="showConfirmPayment">
                  <button [disabled]="!invoiceNumber" (click)="showModal()" class="ui primary button">Confirmă
                    plata</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sixteen wide column" *ngIf="showReceipt">
          <div class="ui segment">
            <button *ngIf="showPrintOut" printSectionId="printableArea" class="mini ui primary button print-button"
                    styleSheetFile="assets/css/print-css.css" title="Printează" ngxPrint>
              <i class="print icon"></i>
            </button>
            <!-- <button *ngIf="showPrintOut" (click)="print('printableArea')" class="mini ui primary button print-button"
              title="Printeaza"> to test
              <i class="print icon"></i>
            </button> -->

            <div id="printableArea">
              <app-payment-receipt [showPrintOut]="showPrintOut" [artist]="artist"
                                   [debtsAfterCalculation]="debtsAfterCalculation" [valueDate]="valueDate" [invoiceNumber]="invoiceNumber"
                                   [amountToPay]="amountToPay">
              </app-payment-receipt>
              <div class="duplicate-print-out">
                <div class="cut-here">
                  <i class="cut icon"></i>
                  <div class="doted-border"></div>
                </div>
                <app-payment-receipt [showPrintOut]="showPrintOut" [artist]="artist"
                                     [debtsAfterCalculation]="debtsAfterCalculation" [valueDate]="valueDate"
                                     [invoiceNumber]="invoiceNumber" [amountToPay]="amountToPay">
                </app-payment-receipt>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
