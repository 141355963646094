<div class="ui segment status">
    <div class="ui grid stackable">
        <div class="thirteen wide computer five wide tablet sixteen wide mobile column">
            <div class="ui large breadcrumb">
                <a class="section" [routerLink]="'/manage-artist'">Artiști</a>
                <i class="right chevron icon divider"></i>
                <h3 class="section active"> Date despre artist
                </h3>
            </div>
        </div>
        <div class="three wide computer five wide tablet sixteen wide mobile column">
            <button class="ui mini blue right floated button" (click)="save()" [ngClass]="{'loading': loading}" [disabled]="loading">
                <i class="save icon"></i>
                <span>Salvează</span>
            </button>
        </div>
    </div>


    <div class="ui divider"></div>
    <div class="ui grid">
        <div class="field">
            <br><label><b>Nume: </b> <span>{{artist.lastName}}</span></label><br>
            <label><b>Prenume: </b> <span>{{artist.firstName}}</span></label><br>
            <label><b>Filiala: </b> <span>{{artist.branch.name}}</span></label><br>
            <label><b>Specializare: </b> <span>{{artist.speciality.name}}</span></label><br>
            <label><b>Statut: </b><span>{{artist.statut.name}}</span></label><br>
        </div>
        <div class="sixteen wide computer twelve wide table sixteen wide mobile column">
            <form class="ui form create event" autocomplete="off" *ngIf="!loading">
                <div class="ui mini error message"></div>
                <div class="ui mini red message" *ngIf="errors.length">
                    <ul class="list">
                        <li *ngFor="let error of errors" [innerHTML]="error"></li>
                    </ul>
                </div>

                <label>Studii</label>
                <div class="three fields">
                    <div class="field">
                        <label>Universitatea</label>
                        <input type="text" name="university" placeholder="Universitatea" [(ngModel)]="model.university" />
                    </div>
                    <div class="field">
                        <label>Anul Absolvirii</label>
                        <input type="text" name="anulAbsolvirii" placeholder="Anul Absolvirii" [(ngModel)]="model.graduationYear" />
                    </div> 
                    <div class="field">
                        <label>Forma de studii</label>
                        <input type="text" name="studii" placeholder="Forma de studii" [(ngModel)]="model.studies" />
                    </div>
                </div>

                <label>Expoziții</label>
                <div class="three fields">
                    <div class="field">
                        <label>Titlu expoziție</label>
                        <input type="text" name="exposition" placeholder="Titlu expoziție" [(ngModel)]="model.exposition" />
                    </div>
                    <div class="field">
                        <label>Data (zi/luna/an)</label>
                        <fm-datepicker [(model)]="model.expositionDate" placeholder="Data (zi/luna/an)"></fm-datepicker>
                    </div>
                    <div class="field">
                        <label>Locația</label>
                        <input type="text" name="location" placeholder="Locația" [(ngModel)]="model.location" />
                    </div>
                </div>

                <label>Premii</label>
                <div class="three fields">
                    <div class="field">
                        <label>Denumire premiu</label>
                        <input type="text" name="award" placeholder="Denumire premiu" [(ngModel)]="model.award" />
                    </div>
                    <div class="field">
                        <label>Data (zi/luna/an)</label>
                        <fm-datepicker [(model)]="model.awardDate" placeholder="Data (zi/luna/an)"></fm-datepicker>
                    </div>
                    <div class="field">
                        <label>Acordat de</label>
                        <input type="text" name="grantedBy" placeholder="Acordat de" [(ngModel)]="model.grantedBy" />
                    </div>
                </div>

                <div class="two fields">
                    <div class="field">
                        <label>Altele</label>
                        <input type="text" name="altele" placeholder="Altele" [(ngModel)]="model.others" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>