import Swal from 'sweetalert2/dist/sweetalert2.js';
import { setAlert } from 'src/shared/helpers/global.func';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { handleSwaggerError } from '~shared/helpers';
import { BaseComponent } from '~shared/helpers/classes/base-component/base-component';
import { Column } from '~shared/helpers/classes/pagination/pagination';
import { ArtistPaymentService } from '~shared/services/artist-payment/artist-payment.service';
import { ArtistPaymentModel } from '~shared/services/artist-payment/models';
import { ArtistService } from '~shared/services/artist/artist.service';
import { ArtistModel } from '~shared/services/artist/models';
import { MemberShortModel } from '~shared/services/member/models/models';
import { ArtistDebtService } from '~services/artist-debt/artist-debt.service';
import {
  ArtistDebtExtendedModel,
  DebtAfterCalculationModel,
  DebtToBeSentModel
} from '~services/artist-debt/models';
import { Location } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-debt-details',
  templateUrl: './debt-details.component.html',
  styleUrls: ['./debt-details.component.scss']
})
export class DebtDetailsComponent extends BaseComponent<ArtistPaymentModel> implements OnDestroy, OnInit {
  artistPayment = new ArtistPaymentModel();
  currentUser: MemberShortModel = new MemberShortModel();
  artist: ArtistModel = null;
  selectedArtistId = 0;

  debts: Array<ArtistDebtExtendedModel> = []
  payments: Array<ArtistPaymentModel> = []

  override columns: Column[] = [];

  deptTableColumns: Column[] = [
    new Column('observations', 'observații'),
    new Column('amount', 'Suma'),
    new Column('remainings', 'Rest de plătit'),
    new Column('year', 'An'),
    new Column('isPaid', 'plătit în întregime'),
  ];
  paymentTableColumns: Column[] = [
    new Column('year', 'An'),
    new Column('amount', 'Suma'),
    new Column('paid', 'Plătit'),
    new Column('remainingToPay', 'Observații'),
    new Column('remainingToPay', 'Rest de Plată'),
  ];

  amountToPay = 0;
  remainingAmountToPay = 0;
  debtsAfterCalculation: Array<DebtAfterCalculationModel> = [];
  showReceipt = false;
  showPrintOut = false;
  showTable = false
  valueDate: Data = new Date();
  invoiceNumber: number
  observations: string
  timeout: any;

  pdfAmount = 0;
  showConfirmPayment = true;
  showBackButton = false;


  constructor(service: ArtistPaymentService, private route: ActivatedRoute, private artistService: ArtistService,
              private artistDebtService: ArtistDebtService, private location: Location) {
    super(service, 'plata', true);
    this.service = service;
    this.route.params.subscribe((params) => {
      if (params.artistId) {
        this.selectedArtistId = Number(params.artistId);
        this.showBackButton = true;
      }

      this.pagination.artistId = this.selectedArtistId;
    })
  }

  ngOnInit() {
    if (this.selectedArtistId) {
      this.getArtistWithId(this.selectedArtistId);
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  deletePayment(debtId: number) {
    const payment = this.payments.find(
      (payment: ArtistPaymentModel) => payment.debtId === debtId && payment.artistId == this.selectedArtistId
    );  
    
    if (payment)
      this.delete(payment.id);
  }

  onSaveSuccess = () => {
    this.getData();
  }

  setLoading(isLoading) {
    this.loading = isLoading;
  }

  getArtistWithId(event) {
    if (event) {
      this.pagination.artistId = event;
      this.selectedArtistId = event;
      this.getArtistData();
      this.getDebtsAndPayments();
      this.debtsAfterCalculation = [];
      this.showReceipt = false;
      this.amountToPay = 0;
    }
    this.OnscrollDown()
  }

  getDebtsAndPayments() {
    this.loading = true;
    this.service.list(this.selectedArtistId).pipe(finalize(() => {
      this.loading = false;
    })).subscribe((response) => {
      this.payments = response;
      this.artistDebtService.list(this.selectedArtistId).subscribe((response) => {
        this.debts = response.map((debt: ArtistDebtExtendedModel) => {
          const payments = this.payments.filter((payment: ArtistPaymentModel) => debt.id === payment.debtId)
          if (payments && payments.length > 0) {
            let observation = '';
            let totalPaymentAmount = 0;
            payments.forEach(payment => {
              observation = observation ? observation + ' | ' + payment.observations : payment.observations;
              totalPaymentAmount += payment.amount;
            })
            const remaining = debt.amount - totalPaymentAmount;
            debt.observations = observation;
            debt.remainings = remaining < 0 ? 0 : remaining;
            debt.isPaid = debt.remainings <= 0 ? true : false;
          } else {
            debt.remainings = debt.amount;
            debt.isPaid = false;
          }
          return debt;
        });
      })
    })
  }

  private getArtistData() {
    this.artistService.getSingle(this.selectedArtistId).subscribe({
      next: (response) => {
        this.artist = response;
      },
      error: handleSwaggerError.bind(this)
    })
  }


  get totalDept() {
    const sum = this.debts.reduce((accum, object) => {
      return accum + object.remainings;
    }, 0)
    return sum;
  }
  get totalRestToPay() {
    const sum = this.debts.reduce((accum, object) => {
      return accum + object.amount;
    }, 0)
    return sum;
  }

  onCalculateDebts() {
    this.invoiceNumber = 0;
    this.showReceipt = false;
    this.pdfAmount = Number(this.amountToPay);
    this.showConfirmPayment = true;
    this.remainingAmountToPay = Number(this.amountToPay);
    this.debtsAfterCalculation = [];

    const debtsList: Array<ArtistDebtExtendedModel> = this.debts.slice();

    debtsList
      .reverse()
      .forEach((debt: ArtistDebtExtendedModel) => {
        if (debt.remainings === 0) {
          return;
        }
        const remainingToPay = this.getDues(debt.remainings);
        const paid = remainingToPay - debt.remainings;
        if (paid === 0) {
          return;
        }

        this.debtsAfterCalculation.push(
          {
            year: debt.year,
            amount: debt.remainings,
            paid: Math.abs(paid),
            remainingToPay: remainingToPay,
            id: debt.id,
            observations: debt.observations
          });
        this.showTable = true;
      });
    this.OnscrollDown()
  }

  getDues(debtAmount: number) {
    if (this.remainingAmountToPay > 0) {
      const remaining = this.remainingAmountToPay - debtAmount;
      if (remaining <= 0) {
        this.remainingAmountToPay = 0;
        return Math.abs(remaining);
      }
      this.remainingAmountToPay = remaining;
      return 0;
    }
    return debtAmount;
  }

  showModal() {
    // ($('.ui.basic.modal') as any).modal('show');
    Swal.fire({
      title: `Doriți să confirmați plata ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Da',
      cancelButtonText: 'Nu',
    }).then((result) => {
      if (result.isConfirmed) {
        this.onSavePayment();
      }
    })
  }

  showDiv() {
    this.showReceipt = true;
    this.showPrintOut = true;
  }

  onSavePayment = () => {
    const promises = {};
    this.debtsAfterCalculation.forEach((debt: DebtAfterCalculationModel, index: number) => {
      if (debt.paid > 0) {
        const debtToBeSent: DebtToBeSentModel = {
          debtId: debt.id,
          year: debt.year,
          amount: debt.paid,
          remainingToPay: debt.remainingToPay,
          debtAmount: debt.amount,
          artistId: this.artist.id,
          payment: debt.paid,
          recepitNo: this.invoiceNumber,
          observations: debt.observations
        }
        promises[index] = this.service.savePayment(debtToBeSent);
      }
    });

    forkJoin(promises)
      .subscribe((response) => {
          setAlert('success', 'Datorie plătită!')
          this.getDebtsAndPayments();
          // this.amountToPay = 0;
          this.showConfirmPayment = false;
          this.showDiv();
          this.OnscrollDown();
        },
        (error) => {
          setAlert('error', 'Nu a putut face plata');
        });
  }

  get getYearOfPayment() {
    return this.debtsAfterCalculation.map((debt) => {
      if (debt.paid > 0) {
        return debt.year;
      }
      return '';
    }).join(', ');
  }

  onSearchChange(inputValue: string, debt: DebtAfterCalculationModel): void {
    debt.observations = inputValue
  }

  onAddInvoiceNumber(inputValue: number) {
    this.invoiceNumber = inputValue
  }

  goBack() {
    this.location.back()
  }

  OnscrollDown() {
    this.timeout = setTimeout(() => {
      const content = document.getElementById('content')
      content.scroll({
        top: content.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    }, 100);
  }

  ngDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  // print(id = null) {
  //   const html = document.getElementById(id);
  //   const win = (window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top=" + (screen.height - 400) + ",left=" + (screen.width - 840)) as any);
  //   const css = win.document.createElement('link');
  //   css.rel = 'stylesheet';
  //   css.type = 'text/css';
  //   css.href = location.origin + '/assets/css/print-css.css';
  //   css.media = 'all';
  //   win.document.body.innerHTML = html.innerHTML;
  //   win.document.head.appendChild(css);
  //   win.print();
  //   win.onafterprint = () => {
  //     // win.close();
  //   };
  // }

}
