<div class="ui segment">
  <div class="ui grid stackable">
    <div class="four wide computer five wide tablet sixteen wide mobile column">
      <h3>Cotizații</h3>

    </div>
    <div class="nine wide computer five wide tablet sixteen wide mobile column"></div>

    
    <div class="three wide computer five wide tablet sixteen wide mobile column">
      <a class="ui mini fluid  button icon left" (click)="exportExcel()">
        <i class="download icon"></i>
        <span>Exportă cotizații</span>
      </a>
    </div>
  </div>

  <div class="ui divider"></div>
  <div class="ui grid stackable">

    <div class="three wide computer five wide tablet sixteen wide mobile column">
      <fm-dropdown [list]="branchList" [placeholder]="'Filiala'" (change)="onFilterSelection()" [(optionId)]="pagination.branchId">
      </fm-dropdown>
    </div>
    <div class="three wide computer five wide tablet sixteen wide mobile column">
      <fm-dropdown [list]="specialityList" [placeholder]="'Specializare'" (change)="onFilterSelection()" [(optionId)]="pagination.specialityId">
      </fm-dropdown>
    </div>
    <div class="three wide computer five wide tablet sixteen wide mobile column">
      <fm-dropdown [list]="statuteList" [placeholder]="'Statut'" (change)="onFilterSelection()" [(optionId)]="pagination.statutId">
      </fm-dropdown>
    </div>
    <div class="two wide computer five wide tablet sixteen wide mobile column">
      <fm-dropdown [list]="aliveList" [placeholder]="'Toti artistii'" (change)="onFilterSelection()"
                   [(optionId)]="this.aliveOptionId">
      </fm-dropdown>
    </div>
    <div class="two wide computer five wide tablet sixteen wide mobile column">
      <fm-dropdown [list]="qualityList" [placeholder]="'Calitate'" (change)="onFilterSelection()"
                   [(optionId)]="this.qualityOptionId">
      </fm-dropdown>
    </div>
    <div class="three wide computer five wide tablet sixteen wide mobile column">
      <button class="ui mini right floated button" (click)="resetFilters()">
        <i class="undo icon"></i>
        <span>Resetează filtre</span>
      </button>
    </div>
  </div>
  <div class="ui divider"></div>
  <div class="ui grid">

    <div class="table-filter-container">
      <div class="table-filter-search">

        <fm-search [type]="resourceType.Artists" [placeholder]="'Caută nume artist'" [minCharachers]="1"
                   (onChange)="onArtistSelection($event)">
        </fm-search>
        <button (click)="reset()" *ngIf="searchedArtistId" title="reset search">
          <i class="close icon"></i>
        </button>
      </div>

      <div>
        <fm-dropdown [list]="pagination.itemsPerPageList" [(option)]="pagination.itemsPerPage"
                     (change)="pagination.changeItemsPerPage(pagination.itemsPerPage)" [required]="true"></fm-dropdown>
      </div>
    </div>


  </div>

  <div class="ui divider"></div>

  <no-data *ngIf="!data.length && !loading && !error"></no-data>
  <app-loader *ngIf="loading"></app-loader>
  <invalid-request *ngIf="error" [(refresh)]="refresh"></invalid-request>

  <section [hidden]="loading || !data.length">
    <div class="table-container" *ngIf="!error">
      <table class="ui very basic sortable table">
        <thead>
        <tr>
          <th *ngFor="let column of columns" class="sorted">{{column.label}}</th>
          <th style="cursor: default;" class="sorted">Acțiuni</th>
        </tr>
        </thead>
        <tbody>
        <ng-container
          *ngFor="let item of data | paginate: { id:'data', itemsPerPage: pagination.itemsPerPage, currentPage: pagination.page, totalItems: pagination.totalNumberOfItems }; let index = index">
          <tr [hidden]="loading">
            <td [innerHTML]="item.lastName"></td>
            <td [innerHTML]="item.firstName"></td>
            <td [innerHTML]="item.previousLastName"></td>
            <td [innerHTML]="item.quality"></td>
            <td [innerHTML]="item.branch.name"></td>
            <td [innerHTML]="item.speciality.name"></td>
            <td [innerHTML]="item.statut.name"></td>
            <td [innerHTML]="item.phoneNumber"></td>
            <td [innerHTML]="item.email"></td>
            <td>
              <a [routerLink]="'/manage-debt/' + item.id">
                <i class="info icon" title="Cotizații"></i>
              </a>
            </td>
          </tr>
        </ng-container>
        </tbody>
        <tfoot [hidden]="!data.length">
        <tr>
          <th [attr.colspan]="columns.length + 2" [hidden]="!data.length">
            <pagination-controls (pageChange)="pagination.changePage($event)" id="data" [previousLabel]="'Anteriorul'"
                                 [nextLabel]="'Următorul'">
            </pagination-controls>
          </th>
        </tr>
        </tfoot>
      </table>
    </div>
  </section>
</div>
