import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '~shared/shared.module';
import { MainComponent } from './main.component';
import { RoleGuard } from '~shared/services/auth/guards/role.guard';
import { ManageArtistComponent } from './manage-artist/manage-artist.component';
import { AddArtistComponent } from './manage-artist/add-artist/add-artist.component';
import { Role } from '~shared/helpers';
import { AddArtistDataComponent } from './manage-artist/add-artist-data/add-artist-data.component';
import { ManageBranchComponent } from './manage-branch/manage-branch.component';
import { ManageDecisionComponent } from './manage-decision/manage-decision.component';
import { ManageSpecialityComponent } from './manage-speciality/manage-speciality.component';
import { AddBranchComponent } from './manage-branch/add-branch/add-branch.component';
import { AddSpecialityComponent } from './manage-speciality/add-speciality/add-speciality.component';
import { AddDecisionComponent } from './manage-decision/add-decision/add-decision.component';
import { ProfileComponent } from '~shared/components/profile/profile.component';
import { ManageStatuteComponent } from './manage-statute/manage-statute.component';
import { AddStatuteComponent } from './manage-statute/add-statute/add-statute.component';
import { ManagePaymentComponent } from '~app/main/manage-payment/manage-payment.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { AddUserComponent } from './manage-users/add-user/add-user.component';
import { AddPaymentComponent } from './manage-payment/add-payment/add-payment.component';
import { NgxPrintModule } from 'ngx-print';
import { PaymentReceiptComponent } from './manage-debt/payment-receipt/payment-receipt.component';
import { DebtsListComponent } from "~app/main/manage-debt/debt-list/debts-list.component";
import { NgxPaginationModule } from "ngx-pagination";
import { DebtDetailsComponent } from "~app/main/manage-debt/debt-details/debt-details.component";

// g map
const apiKey = "AIzaSyD0iV8dBkmtwRWDNZelngDGBKs1WcJua7U";

const MainRoutes: Routes = [
  // { path: '', redirectTo: '/manage-artist', pathMatch: 'full' },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'manage-artist',
        component: ManageArtistComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Presedinte, Role.Contabilitate] }
      },
      {
        path: 'manage-artist/:id',
        component: AddArtistComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Contabilitate] }
      },
      {
        path: 'manage-debt/:artistId',
        component: DebtDetailsComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Presedinte, Role.Contabilitate] }
      },
      {
        path: 'manage-debt',
        component: DebtsListComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Presedinte, Role.Contabilitate] }
      },
      {
        path: ':artistId/manage-artist-data/:id',
        component: AddArtistDataComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Contabilitate] }
      },
      {
        path: 'manage-branch',
        component: ManageBranchComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Presedinte, Role.Contabilitate] }
      },
      {
        path: 'manage-branch/:id',
        component: AddBranchComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Contabilitate] }
      },
      {
        path: 'manage-speciality',
        component: ManageSpecialityComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Presedinte, Role.Contabilitate] }
      },
      {
        path: 'manage-speciality/:id',
        component: AddSpecialityComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Contabilitate] }
      },

      {
        path: ':artistId/manage-artist-payment',
        component: ManagePaymentComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Contabilitate] }
      },
      {
        path: ':artistId/manage-decision',
        component: ManageDecisionComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Contabilitate] }
      },
      {
        path: ':artistId/manage-decision/:id',
        component: AddDecisionComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Contabilitate] }
      },
      {
        path: 'manage-statute',
        component: ManageStatuteComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Presedinte, Role.Contabilitate] }
      },
      {
        path: 'manage-statute/:id',
        component: AddStatuteComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Contabilitate] }
      },
      {
        path: 'manage-users',
        component: ManageUsersComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Presedinte, Role.Contabilitate] }
      },
      {
        path: 'manage-users/:id',
        component: AddUserComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Contabilitate] }
      },

      {
        path: 'manage-payment',
        component: ManagePaymentComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Secretariat, Role.Contabilitate] }
      },

      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [RoleGuard],
        data: { roles: [Role.Admin, Role.Member, Role.Contabilitate, Role.Secretariat] }
      }
    ]

  },
  { path: '**', redirectTo: 'not-found', pathMatch: 'full' }
];

@NgModule({
  imports: [
    FormsModule,
    NgxPrintModule,
    SharedModule,
    RouterModule.forChild(MainRoutes),
    NgxPaginationModule,
  ],
  declarations: [
    MainComponent,
    ManageArtistComponent,
    AddArtistComponent,
    AddArtistDataComponent,
    ManageBranchComponent,
    ManageDecisionComponent,
    ManageSpecialityComponent,
    AddBranchComponent,
    AddSpecialityComponent,
    AddDecisionComponent,
    ManageStatuteComponent,
    AddStatuteComponent,
    ManageUsersComponent,
    AddUserComponent,
    ManagePaymentComponent,
    AddPaymentComponent,
    PaymentReceiptComponent,
    DebtsListComponent,
    DebtDetailsComponent,
  ],
  providers: []
})

export class MainModule { }
