export class ArtistDataModel{
    public id: number;
    public artistId: number;
    public university: string;
    public graduationYear: string;
    public studies: string;
    public exposition: string;
    public expositionDate?: Date;
    public location: string;
    public award: string;
    public awardDate?: Date;
    public grantedBy: string;
    public others: string;
}