import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService extends BaseService {
  constructor() {
    super('paymentType');
  }
}

