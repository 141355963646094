import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { APP_INITIALIZER, NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';

// Modules ----------
import { SharedModule } from '~shared/shared.module';

// Components -------
import { AppComponent } from './app.component';
import { AuthGuard } from '~services/auth/guards/auth.guard';
import { AuthComponent } from './auth/auth.component';
import { AuthService } from '~services/auth/auth.service';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthModule } from './auth/auth.module';
import { MainModule } from './main/main.module';
import { RoleGuard } from '~shared/services/auth/guards/role.guard';
import { ErrorInterceptor } from '~shared/interceptors/error.interceptor';
import { JwtInterceptor } from '~shared/interceptors/token.interceptor';
import { NotFoundComponent } from '~shared/components/not-found/not-found.component';
import { AuthNames } from '~shared/helpers';
import { NgxImageCompressService } from "ngx-image-compress";

const routes: Routes = [
  { path: AuthNames.prefix, loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: '', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', redirectTo: 'not-found' }
];


@NgModule({
  declarations: [AppComponent, AuthComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AuthModule,
    MainModule,
    SharedModule,
    RouterModule.forRoot(routes)
  ],
  providers: [
    AuthGuard, RoleGuard, NgxImageCompressService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: (auth: AuthService) => () => auth.onAppInit().toPromise(), deps: [AuthService], multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
