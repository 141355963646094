import { Component, Input, AfterViewInit, ElementRef, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, SimpleChange, ViewEncapsulation } from '@angular/core';

import { map, isArray, find, isNil } from 'lodash-es';
import { IResource, ResourceService } from '~shared/services';

declare var $: any;
// import { ResourceService, IResource } from 'services';

interface IListItem {
    id?: number; value?: string | number; name?: string; selected?: boolean;
}
// Mandatory value & selected values
interface IDropdownListItem extends IListItem {
    value: string | number;
    selected: boolean;
}

@Component({
    selector: 'fm-dropdown',
    templateUrl: 'dropdown.component.html',
    encapsulation: ViewEncapsulation.None
})

export class FMDropdownComponent implements AfterViewInit, OnChanges {

    @Input() required: boolean = false;
    @Input() placeholder: string = 'Select a value';
    @Input() option: string = null;
    @Input() optionId: number = null;

    @Output() optionChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() optionIdChange: EventEmitter<number> = new EventEmitter<number>();

    @Input() set list(list: IListItem[]) {

        let data;
        try {
            data = JSON.parse(JSON.stringify(list || []));
        } catch (error) {
            data = [];
        }
        if (!isArray(data)) {
            console.error('List should not be an object:', data);
            data = [];
        }

        let selected = null;

        data = map(data, (item: IListItem) => {
            item.value = item.value || item.name;
            item.selected = item.selected || (item.value === this.option || (this.optionId && item.id === this.optionId));
            if (item.selected) selected = item;
            return item;
        });

        if (this.required && data.length && !selected) {
            data[0].selected = true;
            selected = data[0];
        }
        this.dropdownList = (data as IDropdownListItem[]);
        setTimeout(() => {
            ($(this.element.nativeElement.children[0]) as any).dropdown('set selected', selected ? selected.value : '');
        }, 100);
    }

    @Input()
    set remoteSource(type: number) {
        if (type) {
            this.resourceService.getResource(type)
                .subscribe(
                    (response: IResource[]) => {
                        this.list = map(response, (item) => {
                            return <IListItem>{
                                id: item.id,
                                name: item.name,
                                selected: false,
                                value: (<any>item).value || item.name
                            };
                        });
                    },
                    (error) => {
                        console.error('Error in remove source for dropdown:', error);
                        this.list = [];
                        // TODO
                    }
                );
        }
    }

    dropdownList: IDropdownListItem[] = [];
    // for more settings and details : https://semantic-ui.com/modules/dropdown.html#/settings
    private options = {
        // Event used to trigger dropdown (Hover, Click, Custom Event)
        on: 'click',
        // Whether values with matching cases should be treated as identical when adding them to a dropdown.
        ignoreCase: true,
        // When set to true will fire onChange even when the value a user select matches the currently selected value.
        allowReselection: false,
        // Whether search selection should allow users to add their own selections, works for single or multiselect.
        allowAditions: false,
        // The minimum characters for a search to begin showing results
        minCharacters: 1,
        // Whether search selection will force currently selected choice when element is blurred.
        forceSelection: false

    };
    // , private _resourceService: ResourceService
    constructor(public element: ElementRef, private resourceService: ResourceService) { }

    ngAfterViewInit() {
        ($(this.element.nativeElement.children[0]) as any).dropdown(this.options);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setSelected(changes.optionId, ['id', this.optionId]);
        this.setSelected(changes.option, ['name', this.option]);
    }

    set model(option: string) {
        if (option === this.option) return;

        this.option = option;
        this.optionChange.emit(this.option);
        const item = find(this.dropdownList, { value: this.option });
        if (item) {
            this.dropdownList.forEach((i) => {
                i.selected = item.id === i.id;
            });
            this.optionIdChange.emit(item.id);
        }

    }

    private setSelected(option: SimpleChange, condition: Array<string | number>) {

        if (!option) return;
        if (option.currentValue === option.previousValue) return;

        const selected = find(this.dropdownList, condition) as any;
        if (selected && !selected.selected) {
            setTimeout(() => ($(this.element.nativeElement.children[0]) as any).dropdown('set selected', selected.value), 100);
        }
        if (isNil(option.currentValue) && !this.required) {
            setTimeout(() => {
                ($(this.element.nativeElement.children[0]) as any).dropdown('clear');
            }, 100);
        }
    }
}
