<div class="ui grid stackable">
    <div class="thirteen wide computer five wide tablet sixteen wide mobile column">
        <h3 class="component-heading">Schimbă parola</h3>
    </div>

    <div class="three wide computer five wide tablet sixteen wide mobile column">
        <button class="ui mini blue right floated button" (click)="savePassword()"
            [ngClass]="{loading: loading}" [disabled]="loading"><i class="save icon"></i> <span>Salvează</span>
        </button>
    </div>
</div>

<div class="ui divider"></div>

<div class="ui grid">
    <div class="row">
        <div class="sixteen wide tablet eight wide computer column">
            <form class="ui form">
                <div class="ui mini error message"></div>
                <div class="ui mini red message" *ngIf="errors.length">
                    <ul class="list">
                        <li *ngFor="let error of errors" [innerHTML]="error"></li>
                    </ul>
                </div>
                <div class="sixteen wide field required">
                    <label>Parola Actuală</label>
                    <div class="ui left icon input">
                        <i class="lock icon"></i>
                        <input type="text" name="parolaActuala" placeholder="Parola Actuală"
                            [(ngModel)]="passwordModel.currentPassword" type="password">
                    </div>

                </div>
                <div class="sixteen wide field required">

                    <label>Parola Nouă</label>
                    <div class="ui left icon input">
                        <i class="lock icon"></i>
                        <input type="text" name="parola" placeholder="Parola Nouă"
                            [(ngModel)]="passwordModel.newPassword" type="password">
                    </div>
                </div>

                <div class="sixteen wide field required">
                    <label>Confirmă Parola</label>
                    <div class="ui left icon input">
                        <i class="lock icon"></i>
                        <input type="text" name="confirmaParola" placeholder="Confirmă Parola" type="password"
                            [(ngModel)]="passwordModel.confirmPassword">
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>