import {StatuteModel} from "~services/statute/models";
import {SpecialityModel} from "~services/speciality/models";
import {BranchModel} from "~services/branch/models";

export class ArtistModel {
  public id: number;
  public code: string;
  public ssn: string;
  public firstName: string;
  public lastName: string;
  public previousLastName: string;
  public birthPlace: string;
  public quality: string;
  public address: string;
  public phoneNumber: string;
  public telefonAtl: string;
  public telefonP: string;
  public mobil: string;
  public birthDate?: Date;
  public deathDate?: Date;
  public leaveDate?: Date;
  public assignedUserId?: number;
  public createdById?: number;
  public branchId?: number;
  public branch: BranchModel;
  public specialityId?: number;
  public speciality: SpecialityModel
  public statutId?: number;
  public statut: StatuteModel;
  public dateChanged?: Date;
  public dateTransfer?: Date;
  public artistDataId?: number;
  public email: string;

  public pensionarData: Date
  public pensionarDecizie: string
  public pensionarAn: number

  public stagiarDecizie: string
  public stagiarData: Date
  public stagiarAn: number

  public titularAn: number
  public titularData: Date
  public titularDecizie: string

  public primireAn: number
  public primireData: Date
  public primireDecizie: string

  public legea8An: number
  public legea8Data: Date
  public legea8: string

  public legea118An: number
  public legea118Data: Date
  public legea118: string
}

export class ExportArtistModel {
  public artistId: number;
  public branch: string;
  public speciality: string;
  public statut: string;
  public isAlive: string;
  public quality: string;
}
