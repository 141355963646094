<div class="primary">
    <div class="ui middle aligned center aligned grid">
        <div class="column">

            <h4 style="color:white">Nu știu ce pagină vrei să accesezi:(.
            </h4>

            <div class="neomorphism" *ngIf="user" (click)="logOut()">
                <span>Ieși din aplicație și intră iar. </span>
            </div>
        </div>
    </div>
</div>

