<div class="forgot password">
  <h4>RECUPEREAZĂ PAROLA </h4>
  <p *ngIf="!gotResetCode">Introdu adresa de email și noi îți vom trimite un cod</p>
  <p *ngIf="gotResetCode">Introdu noua parolă și codul de confirmare</p>

  <div class="ui form">
    <div class="ui mini red message" *ngIf="errors.length">
      <ul class="list">
        <li *ngFor="let error of errors" [innerHTML]="error"></li>
      </ul>
    </div>
    <div class="ui mini error message"></div>
    <div class="ui stacked">

      <div *ngIf="!gotResetCode">
        <div class="field">
          <div class="ui left icon input">
            <i class="user icon"></i>
            <input type="text" name="email" placeholder="Adresa de e-mail" [(ngModel)]="email" required="required">
          </div>
        </div>
        <button class="ui fluid medium secondary submit button" (click)="sendResetCode()"
          [ngClass]="{'loading': loading}" [disabled]="loading">Trimite
        </button>
      </div>
      <div *ngIf="gotResetCode">
        <div class="field">
          <div class="ui left icon input">
            <i class="lock icon"></i>
            <input type="password" name="parola" placeholder="Parola" [(ngModel)]="resetPassword.password" />
          </div>
        </div>
        <div class="field">
          <div class="ui left icon input">
            <i class="lock icon"></i>
            <input type="password" name="confirmaParola" placeholder="Confirmă Parola"
              [(ngModel)]="resetPassword.confirmPassword" />
          </div>
        </div>
        <div class="field">
          <div class="ui left icon input">
            <i class="envelope open icon"></i>

            <input type="number" name="cod" placeholder="Codul de Confirmare"
              [(ngModel)]="resetPassword.securityCode" />
          </div>
        </div>
        <button class="ui fluid medium secondary submit button" [ngClass]="{loading: loading}" [disabled]="loading"
          (click)="reset()">Resetează parola
        </button>
      </div>
    </div>
    <br />
    <small>
      <strong>
        <div class="ui centered grid">
          <div class="row">
            <div class="seven wide computer eight wide mobile column centered aligned">
              <small>
                <strong [routerLink]="loginLink">Întoarcete-te la login</strong>
              </small>
            </div>
          </div>
        </div>
      </strong>
    </small>
    <!-- More Spacing computer/tablet only -->
    <div class="ui grid">
      <div class="row">
      </div>
    </div>
  </div>
</div>