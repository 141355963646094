import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '~shared/helpers/classes/base-component/base-component';
import { Column } from '~shared/helpers/classes/pagination/pagination';
import { SpecialityModel } from '~shared/services/speciality/models';
import { SpecialityService } from '~shared/services/speciality/speciality.service';

const columns=[
  new Column('name', 'Nume'),
  new Column('code', 'Cod')
];


@Component({
  selector: 'app-manage-speciality',
  templateUrl: './manage-speciality.component.html',
  styleUrls: ['./manage-speciality.component.scss']
})
export class ManageSpecialityComponent extends BaseComponent<SpecialityModel> implements OnDestroy {
  override columns = columns;

  constructor(service: SpecialityService) {
    super(service, 'speciality');
  }

  exportExcel() {
    this.service.downloadFile();
  }
  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
