import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationRules } from '~shared/helpers';
import { BaseAddComponent } from '~shared/helpers/classes/base-component/base-add-component';
import { Pagination } from '~shared/helpers/classes/pagination/pagination';
import { ArtistService } from '~shared/services/artist/artist.service';
import { ArtistModel } from '~shared/services/artist/models';
import { BranchService } from '~shared/services/branch/branch.service';
import { BranchModel } from '~shared/services/branch/models';
import { DecisionService } from '~shared/services/decision/decision.service';
import { DecisionModel } from '~shared/services/decision/models';
import { SpecialityModel } from '~shared/services/speciality/models';
import { SpecialityService } from '~shared/services/speciality/speciality.service';
import { StatuteModel } from '~shared/services/statute/models';
import { StatuteService } from '~shared/services/statute/statute.service';

const fields = {
  'cod': { rules: ValidationRules.empty },
  'CNP': { rules: ValidationRules.cnp },
  'nume': { rules: ValidationRules.empty },
  'email': { rules: ValidationRules.email },
  'prenume': { rules: ValidationRules.empty },
  'loculNasterii': { rules: ValidationRules.empty },
  'calitate': { rules: ValidationRules.empty },
  'adresa': { rules: ValidationRules.empty },
  'numarDeTelefon': { rules: ValidationRules.empty }
};

@Component({
  selector: 'app-add-artist',
  templateUrl: './add-artist.component.html',
  styleUrls: ['./add-artist.component.scss']
})
export class AddArtistComponent extends BaseAddComponent<ArtistModel> implements OnInit {

  override redirectUrl = '/manage-artist';
  specialityList = new Array<SpecialityModel>();
  branchList = new Array<BranchModel>();
  decisionList = new Array<DecisionModel>();
  statuteList = new Array<StatuteModel>();

  showEditFields: boolean;
  showDateChangedSuspend: boolean;
  showDateChangedReturn: boolean;
  showDateTransfer: boolean;
  artistId: number;

  constructor(service: ArtistService, router: Router, route: ActivatedRoute, private specialityService: SpecialityService,
    private branchService: BranchService, private decisionService: DecisionService, private statuteService: StatuteService) {
    super(service, ArtistModel, fields, 'Artistul', route, router, true);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.getData();
    this.route.params.subscribe((params) => {
      if (params.id) this.artistId = Number(params.id);
    });
  }

  getData() {
    this.specialityService.get(new Pagination(() => { }, 1, 10000)).subscribe((response) => this.specialityList = response);
    this.branchService.get(new Pagination(() => { }, 1, 10000)).subscribe((response) => this.branchList = response);
    this.decisionService.get(new Pagination(() => { }, 1, 10000)).subscribe((response) => this.decisionList = response);
    this.statuteService.get(new Pagination(() => { }, 1, 10000)).subscribe((response) => this.statuteList = response);
  }

  onStatutIdChanged() {
    this.model.dateChanged = null;
    this.showDateChangedSuspend = this.model.statutId == 11;
    this.showDateChangedReturn = this.model.statutId != 11;
  }

  onBranchIdChanged() {
    this.model.dateTransfer = null;
    this.showDateTransfer = true;
  }

  exportExcel() {
    const fileName = `${this.model.firstName} ${this.model.lastName}.xlsx`;
    this.service.exportArtist(this.artistId, fileName);
  }
}
