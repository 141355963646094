<div class="ui segment status">
  <div class="ui grid stackable">
    <div class="eleven wide computer five wide tablet sixteen wide mobile column">
      <div class="ui large breadcrumb">
        <a class="section" [routerLink]="'/manage-artist'">Artiști</a>
        <i class="right chevron icon divider"></i>
        <h3 class="section active" [innerHTML]="id ? 'Editează artistul' : 'Adaugă artist'">
        </h3>
      </div>
    </div>
    <div class="five wide computer five wide tablet sixteen wide mobile column">
      <button class="ui mini blue right floated button" (click)="save()" [ngClass]="{'loading': loading}"
        [disabled]="loading">
        <i class="save icon"></i>
        <span>Salvează</span>
      </button>
      <!-- <a class="ui mini right floated button" [routerLink]="'/' + id + '/manage-artist-data/' + (model.artistDataId || 0)" [ngClass]="{'loading': loading}" *ngIf="model.id">
                <i class="info icon"></i>
                <span>Date despre artist</span>
            </a> -->
      <a class="ui mini right floated button" [routerLink]="'/manage-debt/' + id" [ngClass]="{'loading': loading}"
        *ngIf="model.id">
        <i class="info icon"></i>
        <span>Cotizații</span>
      </a>
    </div>
  </div>

  <div class="ui divider"></div>
  <div class="ui grid">
    <div class="sixteen wide computer twelve wide table sixteen wide mobile column">
      <form class="ui form create event" autocomplete="off" *ngIf="!loading">
        <div class="ui mini error message"></div>
        <div class="ui mini red message" *ngIf="errors.length">
          <ul class="list">
            <li *ngFor="let error of errors" [innerHTML]="error"></li>
          </ul>
        </div>
        <div class="four fields">
          <div class="field required">
            <label>Nume</label>
            <input type="text" name="nume" placeholder="Nume" [(ngModel)]="model.firstName" />
          </div>

          <div class="field required">
            <label>Prenume</label>
            <input type="text" name="prenume" placeholder="Prenume" [(ngModel)]="model.lastName" />
          </div>
          <div class="field required">
            <label>Cod</label>
            <input type="text" name="cod" placeholder="Cod" [(ngModel)]="model.code" />
          </div>

          <div class="field required">
            <label>CNP</label>
            <input type="number" name="ssn" placeholder="CNP" [(ngModel)]="model.ssn" />
          </div>
        </div>

        <div class="five fields">
          <div class="field required">
            <label>Locul Nașterii</label>
            <input type="text" name="loculNasterii" placeholder="Locul Nașterii" [(ngModel)]="model.birthPlace" />
          </div>

          <div class="field required">
            <label>Data nașterii</label>
            <fm-datepicker [(model)]="model.birthDate" [required]="true" placeholder="Data nașterii">
            </fm-datepicker>
          </div>

          <div class="field required">
            <label>Calitate</label>
            <input type="text" name="calitate" placeholder="Calitate" [(ngModel)]="model.quality" />
          </div>
          <div class="field required">
            <label>Adresa de email</label>
            <input type="email" name="email" placeholder="Adresa de email" [(ngModel)]="model.email" />
          </div>

          <div class="field required">
            <label>Număr de telefon</label>
            <input type="text" name="numarDeTelefon" placeholder="Număr de telefon" [(ngModel)]="model.phoneNumber" />
          </div>
        </div>

        <div class="four fields">
          <div class="field required">
            <label>Adresa</label>
            <input type="text" name="adresa" placeholder="Adresa" [(ngModel)]="model.address" />

          </div>
          <div class="field">
            <label>Filiala</label>
            <fm-dropdown [list]="branchList" [placeholder]="'Filiala'" [(optionId)]="model.branchId" (change)="onBranchIdChanged()">
            </fm-dropdown>
          </div>
          <div class="field">
            <label>Specializare</label>
            <fm-dropdown [list]="specialityList" [placeholder]="'Specializare'" [(optionId)]="model.specialityId">
            </fm-dropdown>
          </div>
          <div class="field">
            <label>Statut</label>
            <fm-dropdown [list]="statuteList" [placeholder]="'Statut'" [(optionId)]="model.statutId" (change)="onStatutIdChanged()">
            </fm-dropdown>
          </div>
        </div>

        <div class="four fields">

          <div class="field">
            <label>Data renunțării</label>
            <fm-datepicker [(model)]="model.leaveDate" [required]="true" placeholder="Data renunțării">
            </fm-datepicker>
          </div>

          <div class="field">
            <label>Data decesului</label>
            <fm-datepicker [(model)]="model.deathDate" [required]="true" placeholder="Data decesului">
            </fm-datepicker>
          </div>

          <div class="field" *ngIf="showEditFields && showDateChangedSuspend">
            <label>Data suspendării</label>
            <fm-datepicker [(model)]="model.dateChanged" placeholder="Data suspendării"></fm-datepicker>
          </div>

          <div class="field" *ngIf="showEditFields && showDateChangedReturn">
            <label>Data revenirii</label>
            <fm-datepicker [(model)]="model.dateChanged" placeholder="Data revenirii"></fm-datepicker>
          </div>

          <div class="field" *ngIf="showEditFields && showDateTransfer">
            <label>Data transfer</label>
            <fm-datepicker [(model)]="model.dateTransfer" placeholder="Data transfer"></fm-datepicker>
          </div>

        </div>
      </form>
    </div>
  </div>
  <div class="ui divider"></div>

  <a class="section">Deciziile</a>

  <div class="ui divider"></div>

  <div class="ui grid">
    <div class="sixteen wide computer twelve wide table sixteen wide mobile column">
      <form class="ui form create event" autocomplete="off" *ngIf="!loading">
        <div class="three fields">

          <div class="field ">
            <label>Primire1 Decizia </label>
            <input type="text" name="primire1 decizia
            " placeholder="Primire1 Decizia
            " [(ngModel)]="model.primireDecizie" />
          </div>
          <div class="field ">
            <label>Din</label>
            <fm-datepicker [(model)]="model.primireData" [required]="true" placeholder="Din">
            </fm-datepicker>
          </div>
        </div>
        <div class="three fields">
          <div class="field ">
            <label>Stagiar Decizia </label>
            <input type="text" name="stagiar decizia
            " placeholder="Stagiar Decizia
            " [(ngModel)]="model.stagiarDecizie" />
          </div>
          <div class="field ">
            <label>Din</label>
            <fm-datepicker [(model)]="model.stagiarData" [required]="true" placeholder="Din">
            </fm-datepicker>
          </div>
        </div>
        <div class="three fields">
          <div class="field ">
            <label>Titular Decizia </label>
            <input type="text" name="titular decizia
            " placeholder="Titular Decizia
            " [(ngModel)]="model.titularDecizie" />
          </div>
          <div class="field ">
            <label>Din</label>
            <fm-datepicker [(model)]="model.titularData" [required]="true" placeholder="Din">
            </fm-datepicker>
          </div>
        </div>
        <div class="three fields">
          <div class="field ">
            <label>Pensionar Decizia</label>
            <input type="text" name="pensionar Decizia
            " placeholder="Pensionar Decizia
            " [(ngModel)]="model.pensionarDecizie" />
          </div>
          <div class="field ">
            <label>Din</label>
            <fm-datepicker [(model)]="model.pensionarData" [required]="true" placeholder="Din">
            </fm-datepicker>
          </div>
        </div>
        <div *ngIf="model.deathDate == null" class="three fields">
          <div class="field ">
            <label>Legea 8/2006</label>
            <input type="text" name="legea8
            " placeholder="Legea 8/2006
            " [(ngModel)]="model.legea8" />
          </div>
          <div class="field ">
            <label>Din</label>
            <fm-datepicker [(model)]="model.legea8Data" [required]="true" placeholder="Din">
            </fm-datepicker>
          </div>
          <div class="field ">
            <label>Încetează</label>
            <fm-datepicker placeholder="Încetează">
            </fm-datepicker>
          </div>
        </div>
        <div *ngIf="model.deathDate == null" class="three fields">
          <div class="field ">
            <label>Legea 118/2002</label>
            <input type="text" name="legea118
            " placeholder="Legea 118/2002
            " [(ngModel)]="model.legea118" />
          </div>
          <div class="field ">
            <label>Din</label>
            <fm-datepicker [(model)]="model.legea118Data" [required]="true" placeholder="Din">
            </fm-datepicker>
          </div>
          <div class="field ">
            <label>Încetează</label>
            <fm-datepicker [(model)]="model.deathDate" placeholder="Încetează">
            </fm-datepicker>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="ui divider"></div>

  <app-manage-branch-history [selectedArtistId]="artistId"></app-manage-branch-history>

  <div class="ui divider"></div>

  <app-manage-speciality-history [selectedArtistId]="artistId"></app-manage-speciality-history>
  <div class="ui divider"></div>

  <app-manage-statut-history [selectedArtistId]="artistId"></app-manage-statut-history>

</div>
