import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../auth.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService) { }

    canActivate(): Observable<boolean> {
        setTimeout(() => {
            if (this.authService.isLoggedIn()) this.authService.redirectToSpecificUrl();
        }, 10);
        return of(true);
    }
}
