import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { asObservable, Role } from 'src/shared/helpers';

import { BaseService } from '../base.service';
import { Resource, IResource, IExtendedResource } from './models';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

enum ResourceType {
  Roles = 1,
  Artists = 2
}

@Injectable({
  providedIn: 'root'
})
class ResourceService extends BaseService {

  data = {};

  constructor() {

    super('resource');

    this.data[ResourceType.Roles] = { link: 'roles', items: [
      (new Resource(Role.Admin, Role[Role.Admin])), 
      (new Resource(Role.Presedinte, Role[Role.Presedinte])),
      (new Resource(Role.Contabilitate, Role[Role.Contabilitate])),
      (new Resource(Role.Secretariat, Role[Role.Secretariat])),
      (new Resource(Role.Member, Role[Role.Member]))] };

    this.data[ResourceType.Artists] = { link: 'artist/autocomplete', items: [] };

  }


  getResource(type: ResourceType): Observable<Resource[]> {

    if (!this.data[type]) {
      console.error('Data object not declared for type: ', type);
      return asObservable([]);
    }

    if (this.data[type].items.length) return asObservable(this.data[type].items);

    return this.http.get<Resource[]>(this.url + '/' + this.data[type].link)
      .pipe(map((response) => {
        this.data[type].items = response.map((item) => new Resource(item.id, item.name));
        return this.data[type].items;
      }));
  }

  getAutocompleteResults(type: number, query: string): Observable<any> {
    if (!this.data[type]) {
      return;
    }

    const params = new HttpParams({ fromString: "SearchText=" + query });
    return this.http.get(environment.apiUrl + this.data[type].link, { params, observe: 'response' }).pipe(
      map((response: any) => {
        return response.body || [];
      })
    );
  }

}

export { ResourceType, ResourceService, Resource, IResource, IExtendedResource };
