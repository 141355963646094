<div class="ui pushable segment">
    <sidebar></sidebar>
    <div class="pusher">
        <header></header>
        <div id="content" class="ui basic horizontal very padded segment">
            <router-outlet></router-outlet>
        </div>
        <footer></footer>

    </div> 
    
</div>

