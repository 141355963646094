
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { xor } from 'lodash';

@Component({
    selector: 'invalid-request',
    templateUrl: 'invalid-request.component.html',
})
export class InvalidRequestComponent {
    @Input() refresh: () => void;
    @Input() text: string = 'Datele nu au putut fi încărcate din cauza unei erori.';

    @Output('refreshChange') change = new EventEmitter<any>();

    constructor() {}
}
