import { Component, OnInit } from '@angular/core';
import { AuthService } from '~shared/services/auth/auth.service';
import { IRouteComponent } from '~shared/helpers/global.class';
import { Role } from '~shared/helpers/global.enum';

@Component({
    selector: 'sidebar',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['sidebar.component.scss']
})

export class SideBarComponent implements OnInit {
    routes: Array<IRouteComponent> = [];

    constructor(private auth: AuthService) {
    }

    ngOnInit() {
        this.auth.getCurrentUser().subscribe((user) => {
            this.routes = this.auth.provideSidebarRoutes();
        });
    }

    logOut() {
        this.auth.logout();
    }

}