import { Component, Output, EventEmitter, Input, AfterContentInit, ViewEncapsulation } from '@angular/core';
import { uniqueIdentifier } from '~shared/helpers';

@Component({
    selector: 'toggle',
    templateUrl: 'toggle.component.html',
    styleUrls: ['toggle.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ToggleComponent implements AfterContentInit {

  
    @Input() disabled = false;
    @Input() model: boolean = false;
    @Input() placeholder: string = '';
    @Output() modelChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() update: EventEmitter<boolean> = new EventEmitter<boolean>();

    uniqueId = 'toggle' + uniqueIdentifier();

    constructor() { }

    ngAfterContentInit() {      
    }
};