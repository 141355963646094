<div class="ui segment status">
  <div class="ui grid stackable">
    <div class="thirteen wide computer five wide tablet sixteen wide mobile column">
      <div class="ui large breadcrumb">
        <a class="section" [routerLink]="'/manage-users'">Utilizatori</a>
        <i class="right chevron icon divider"></i>
        <h3 class="section active">{{id ? 'Editează utilizator': 'Adaugă utilizator'}}
        </h3>
      </div>
    </div>

    <div class="three wide computer five wide tablet sixteen wide mobile column">
      <button class="ui mini blue right floated button" (click)="save()" [ngClass]="{'loading': loading}"
        [disabled]="loading">
        <i class="save icon"></i>
        <span>Salvează</span>
      </button>
    </div>
  </div>


  <div class="ui divider"></div>
  <div class="ui grid">
    <div class="sixteen wide computer twelve wide table sixteen wide mobile column">
      <form class="ui form">
        <!-- First Name & Last Name -->
        <div class="four fields">
          <div class="field required">
            <label>Prenume</label>
            <div class="ui input">
              <input name="prenume" type="text" [(ngModel)]="model.firstName">
            </div>
          </div>
          <div class="field required">
            <label>Nume</label>
            <div class="ui input">
              <input name="nume" type="text" [(ngModel)]="model.lastName">
            </div>
          </div>

          <div class="field required">
            <label>Password</label>
            <div class="ui input">
              <input name="password" type="password" [(ngModel)]="model.password">
            </div>
          </div>

          <div class="field required">
            <label>Confirm Password</label>
            <div class="ui input">
              <input name="confirm-password" type="password" [(ngModel)]="model.confirmPassword">
            </div>
          </div>
        </div>
        <div class="four fields">
          <div class="field required">
            <label>Email</label>
            <div class="ui input" [ngClass]="{'disabled': id}">
              <input name="email" type="email" [(ngModel)]="model.email">
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <br />
  <br />
</div>
