 <div class="ui segment">
    <div class="ui grid stackable">
        <div class="four wide computer five wide tablet sixteen wide mobile column">
            <h3>Lista utilizatori</h3>

        </div>

        <div class="nine wide computer one wide tablet sixteen wide mobile column">
        </div>
        <div class="three wide computer five wide tablet sixteen wide mobile column">
             <a class="ui mini fluid blue  button icon left" [routerLink]="'0'" >
                <i class="plus square icon"></i>
                <span> Adaugă utilizator</span>
            </a>
        </div>
    </div>
    <div class="ui divider"></div>
    <div class="ui grid stackable">
        <div class="three wide computer four wide tablet sixteen wide mobile column">
            <div class="ui mini fluid icon  input" [ngClass]="{'loading': loading}">
                <input class="prompt" type="text" placeholder="Caută utilizator" #searchInput>
                <i class="search icon"></i>
            </div>
        </div>

        <div class="four wide computer four wide tablet sixteen wide mobile column">

        </div>

        <div class="three wide computer four wide tablet sixteen wide mobile column">
            <fm-dropdown [list]="roles" [placeholder]="'Role'" (change)="onRoleSelection()" [(optionId)]="pagination.roleId">
            </fm-dropdown>
        </div>
        <div class="two wide computer five wide tablet sixteen wide mobile column">
            <button class="ui mini right floated button" (click)="resetFilters()">
              <i class="undo icon"></i>
              <span>Resetează filtre</span>
            </button>
          </div>
        <div class="two wide computer three wide tablet sixteen wide mobile column" id="good-standing-filter">
        </div>

        <div class="two wide computer three wide tablet sixteen wide mobile column">
            <fm-dropdown [list]="pagination.itemsPerPageList" [(option)]="pagination.itemsPerPage"
                (change)="pagination.changeItemsPerPage(pagination.itemsPerPage)" [required]="true">
            </fm-dropdown>
        </div>

    </div>
    <div class="ui divider"></div>


    <no-data *ngIf="!data.length && !loading && !error"></no-data>
    <app-loader *ngIf="loading"></app-loader>
    <invalid-request *ngIf="error" [(refresh)]="refresh"></invalid-request>

    <section [hidden]="loading || !data.length">
        <div class="table-container" *ngIf="!error">
            <table class="ui very basic sortable table">
                <thead>
                    <tr>
                        <th *ngFor="let column of columns" class="sorted">{{column.label}}</th>
                        <th style="cursor: default;" class="sorted">Acțiuni</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container
                        *ngFor="let item of data | paginate: { id:'users-list', itemsPerPage: pagination.itemsPerPage, currentPage: pagination.page, totalItems: pagination.totalNumberOfItems }">
                        <tr [hidden]="loading">
                            <td [innerHTML]="item.firstName"></td>
                            <td [innerHTML]="item.lastName"></td>
                            <td [innerHTML]="item.email"></td>
                            <td [innerHTML]="matchRole(item.roleId)"></td>

                            <td>
                                <a [routerLink]="'/manage-users/' + item.id">
                                    <i class="edit icon"></i>
                                </a>
                                <a (click)="delete(item.id)">
                                    <i class="close icon" title="Șterge utilizator"></i>
                                </a>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot [hidden]="!data.length">
                    <tr>
                        <th [attr.colspan]="columns.length + 1" [hidden]="!data.length">
                            <pagination-controls (pageChange)="pagination.changePage($event)" id="users-list"
                                [previousLabel]="'Anteriorul'" [nextLabel]="'Următorul'">
                            </pagination-controls>
                        </th>
                        <td>

                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </section>
</div>
