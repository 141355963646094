import { Component, OnInit, AfterViewInit } from '@angular/core';
import { cloneDeep, keys } from 'lodash-es';
import { AuthService } from '~shared/services/auth/auth.service';
import { base64ArrayBuffer, checkForm, checkForPartialUpdate, handleSwaggerError, setAlert, ValidationRules } from '~shared/helpers';
import { AppNotificationService, ResourceType } from '~shared/services';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxImageCompressService } from 'ngx-image-compress';
import { MemberService } from '~shared/services/member/member.service';
import { MemberShortModel } from '~shared/services/member/models/models';
import { ArtistDataService } from '~shared/services/artist-data/artist-data.service';
import { ArtistDataModel } from '~shared/services/artist-data/models';
import { ArtistModel } from '~shared/services/artist/models';
import { ArtistService } from '~shared/services/artist/artist.service';
import { Pagination } from '~shared/helpers/classes/pagination/pagination';
import { BranchService } from '~shared/services/branch/branch.service';
import { DecisionService } from '~shared/services/decision/decision.service';
import { SpecialityService } from '~shared/services/speciality/speciality.service';
import { StatuteService } from '~shared/services/statute/statute.service';
import { BranchModel } from '~shared/services/branch/models';
import { DecisionModel } from '~shared/services/decision/models';
import { SpecialityModel } from '~shared/services/speciality/models';
import { StatuteModel } from '~shared/services/statute/models';

@Component({
    selector: 'settings',
    templateUrl: 'settings.component.html',
    styleUrls: ['../profile.component.scss']
})
export class SettingsComponent implements OnInit, AfterViewInit {
    user: MemberShortModel = new MemberShortModel();
    initialUser: MemberShortModel = new MemberShortModel();

    artist = new ArtistModel();
    specialityList = new Array<SpecialityModel>();
    branchList = new Array<BranchModel>();
    decisionList = new Array<DecisionModel>();
    statuteList = new Array<StatuteModel>();

    resourceType = ResourceType;

    loading = false;
    warning = false;

    imageSrc: string = null;
    image: File;
    private avatarId: number;

    errors = [];
    constructor(
        private authService: AuthService,
        private appNotification: AppNotificationService,
        private member: MemberService,
        private sanitizer: DomSanitizer,
        private artistService: ArtistService,
        private ng2ImgMaxService: NgxImageCompressService,
        private specialityService: SpecialityService,
    private branchService: BranchService, private decisionService: DecisionService, private statuteService: StatuteService) { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.applyFormValidation();
        }, 0);
    }

    ngOnInit() {
        this.authService.getCurrentUser(false).subscribe((response) => {
            this.user = cloneDeep(response);
            this.user.memberId = this.user.id;

            this.initialUser = cloneDeep(this.user);
            this.artistService.getSingle(this.user.id).subscribe((artistData) => {
                this.artist = cloneDeep(artistData);
            }, handleSwaggerError.bind(this, [{ setAlert: true }]))
            //this.getAvatar();
        }, handleSwaggerError.bind(this, [{ setAlert: true }]));

        this.getDropdownsData();
    }

    saveUser() {
        this.warning = false;
        this.errors = [];
        if (!checkForm('.ui.account.form')) return;

        this.loading = true;

        const diff = checkForPartialUpdate(this.initialUser, this.user);
        if(keys(diff).length > 1){
            this.member.patch(diff).subscribe((reponse) => {
                this.loading = false;
                this.saveAvatar();
                setAlert('success', 'Profilul utilizatorului a fost schimbat cu succes');
                this.appNotification.refresh('updateProfile', reponse);
            }, handleSwaggerError.bind(this, [{ setAlert: false }]));
        }

        if (!checkForm('.ui.artist.form')) return;

        this.artistService.save(this.artist).subscribe(() => {
            setAlert('success', 'Datele artistului au fost schimbate cu succes');
        }, handleSwaggerError.bind(this, [{ setAlert: false }]));
    }

    onFileChange(event) {
        const reader = new FileReader();

        console.log(reader, 'reader');

        this.ng2ImgMaxService.compressFile(event.target.files[0], 500, 500).then((result) => {
            console.log('result', result)
            this.image = event.target.files[0];
            reader.readAsDataURL(this.image);

            reader.onload = () => {
                this.imageSrc = reader.result as string;
            };

        }, (e) => {
          console.log(e, 'error');

            setAlert('error', 'Imaginea nu a putut fi comprimată, vă rugăm încercați mai târziu');
        });
    }

    deleteAvatar() {
        if (!this.avatarId) return;

        this.member.deleteAvatar(this.user.id, this.avatarId).subscribe(() => {
            setAlert('success', 'Avatarul a fost șters cu succes');
            this.imageSrc = null;
        }, handleSwaggerError.bind(this, [{ setAlert: true }]));
    }



  getDropdownsData(){
    this.specialityService.get(new Pagination(()=>{}, 1, 10000)).subscribe((response) => this.specialityList = response);
    this.branchService.get(new Pagination(()=>{}, 1, 10000)).subscribe((response) => this.branchList = response);
    this.decisionService.get(new Pagination(()=>{}, 1, 10000)).subscribe((response) => this.decisionList = response);
    this.statuteService.get(new Pagination(()=>{}, 1, 10000)).subscribe((response) => this.statuteList = response);
  }

    private getAvatar() {
        if (!this.user.id) return;

        this.member.getAvatar(this.user.id).subscribe((avatar) => {
            if (avatar?.content) {
                this.imageSrc = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64,' + avatar.content) as string;
                this.avatarId = avatar.id;
            }
        }, handleSwaggerError.bind(this, [{ setAlert: true }]));
    }

    private saveAvatar() {
        if (!this.image) return;

        const reader = new FileReader();
        reader.readAsArrayBuffer(this.image);
        reader.onload = () => {
            // const avatar = new MemberBlobModel();
            // avatar.content = base64ArrayBuffer(reader.result);
            // avatar.blobType = BlobType.image;
            // avatar.name = this.image.name;
            // avatar.id = this.user.id;

            this.member.saveAvatar((this.user.id), null).subscribe((response) => {
            }, handleSwaggerError.bind(this, [{ setAlert: true }]));
        }
    }

    private applyFormValidation() {
        const empty = {
            rules: ValidationRules.empty
        };
        ($('.ui.account.form') as any)
            .form({
                fields: {
                    'nume': empty,
                    'prenume': empty,
                    'email': { rules: ValidationRules.email }
                },
                inline: true,
                on: 'blur'
            });

            ($('.ui.artist.form') as any)
            .form({
                fields: fields,
                inline: true,
                on: 'blur'
            });
    }
}

const fields = {
    'cod': { rules: ValidationRules.empty },
    'ssn': { rules: ValidationRules.cnp },
    'loculNasterii': { rules: ValidationRules.empty },
    'adresa': { rules: ValidationRules.empty },
    'numarDeTelefon': { rules: ValidationRules.empty }
  };
