import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { includes } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
    constructor(private authService: AuthService) { }

    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
        return this.authService.getCurrentUser().pipe(
            map((user: any) => {
                if (user && next.data) {
                    console.log(user, next.data)
                    const ok = includes(next.data.roles, user.roleId);
                    if (ok) return true;

                    this.authService.logout();
                    return false;
                }

                return false;
            }),
            catchError((err) => {
                return of(false);
            })
        );
    }
}
