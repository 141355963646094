import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationRules } from '~shared/helpers';
import { BaseAddComponent } from '~shared/helpers/classes/base-component/base-add-component';
import { StatuteModel } from '~shared/services/statute/models';
import { StatuteService } from '~shared/services/statute/statute.service';

const fields = {
  'nume': { rules: ValidationRules.empty },
  'cod': { rules: ValidationRules.empty }
};

@Component({
  selector: 'app-add-statute',
  templateUrl: './add-statute.component.html',
  styleUrls: ['./add-statute.component.scss']
})
export class AddStatuteComponent extends BaseAddComponent<StatuteModel> implements OnInit {

  override redirectUrl = '/manage-statute';

  constructor(service: StatuteService, router: Router, route: ActivatedRoute) {
    super(service, StatuteModel, fields, 'statut ul', route, router, true);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }
}
