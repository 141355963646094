import { Component, AfterViewInit } from '@angular/core';
import { checkForm, handleSwaggerError, setAlert, ValidationRules } from '~shared/helpers';
import { MemberService } from '~shared/services/member/member.service';
import { ChangePasswordModel } from '~shared/services/member/models/models';
@Component({
    selector: 'change-password',
    templateUrl: 'change-password.component.html',
    styleUrls: ['../profile.component.scss']
})
export class ChangePasswordComponent implements AfterViewInit {

    passwordModel: ChangePasswordModel = new ChangePasswordModel();
    loading = false;

    errors = [];

    constructor(private service: MemberService) { }

    ngAfterViewInit() {
        this.applyFormValidation();
    }

    savePassword() {
        if (!checkForm()) return;
        this.loading = true;

        this.service.changePassword(this.passwordModel).subscribe((response) => {
            this.loading = false;
            this.passwordModel = new ChangePasswordModel();
            setAlert('success', 'Parola a fost schimbată cu succes!');
        }, handleSwaggerError.bind(this, [{ setAlert: false }]));
    }

    private applyFormValidation() {
        ($('.ui.form') as any)
            .form({
                fields: {
                    parolaActuala: {
                        rules: ValidationRules.password
                    },
                    parola: {
                        rules: ValidationRules.password
                    },
                    confirmaParola: {
                        rules: ValidationRules.confirmPassword
                    }
                },
                inline: true,
                on: 'blur'
            });
    }

}
