import { Component } from '@angular/core';

@Component({
    selector: 'app-loader',
    template: `
    <div  style="padding:20%; height:100%; min-height:100px">
        <div class="ui active inverted dimmer">
            <div class="ui text loader"></div>
        </div>
    </div>`
})
export class LoaderComponent {
    constructor() {}
}
