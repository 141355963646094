import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class ArtistDataService extends BaseService {
  constructor() {
    super('artistData');
  }
}
