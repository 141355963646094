
export interface IResource {
    id: number;
    name: string;
    value?: string | number;
}

export class Resource implements IResource {
    constructor(public id: number, public name: string, public value?: string | number, public label?: string) {
        this.value = value || name;
        this.label = label || name;
    }
}

// extend the IResource interface that maps the BE model just as it is +/- what we need computed
// from that model
// with what we'll need in our code to manage different states like:
// - when resetting the user password, we want to display a loading spinner on that user's button =>
//  therefore needing a bool on user that will hold that state
export interface IExtendedResource extends IResource {
    selected: boolean;
    label?: string;
}
