export class MemberShortModel {

    public id: number = null;
    public memberId?: number = null;

    public fullName: string = null;
    public email: string = null;
    public phoneNumber: number = null;

    public firstName: string = null;
    public lastName: string = null;
    
    public isSuperAdmin: boolean = null;
    public isAdmin: boolean = null;
    public isMember: boolean = null;

    public avatar: string = null;

    constructor(member?: MemberShortModel) {
        this.map(member);
    }

    map(member?: MemberShortModel, reverse = false) {

        for (const property in member) {
            const ourProperty = this.toggleFirstLetter(property, reverse);
            if (member.hasOwnProperty(property) && this.hasOwnProperty(ourProperty)) {
                this[ourProperty] = member[property];
            } else {
                console.log('Property not recognized on member model:', property, ourProperty);
            }
        }
    }

    private toggleFirstLetter(string: string, upper = false) {
        const method = upper ? 'toUpperCase' : 'toLowerCase';
        return string.charAt(0)[method]() + string.slice(1);
    }
}


export class ChangePasswordModel {

    public currentPassword: string = null;
    public newPassword: string = null;
    public confirmPassword: number = null;

    constructor(password?: ChangePasswordModel) {
        this.map(password);
    }

    map(password?: ChangePasswordModel, reverse = false) {

        for (const property in password) {
            const ourProperty = this.toggleFirstLetter(property, reverse);
            if (password.hasOwnProperty(property) && this.hasOwnProperty(ourProperty)) {
                this[ourProperty] = password[property];
            } else {
                console.log('Property not recognized on password model:', property, ourProperty);
            }
        }
    }

    private toggleFirstLetter(string: string, upper = false) {
        const method = upper ? 'toUpperCase' : 'toLowerCase';
        return string.charAt(0)[method]() + string.slice(1);
    }
}

export class ResetPasswordModel {

    public memberId: number = null;
    public securityCode: string = null;
    public password: string = null;
    public confirmPassword: string = null;


    constructor(resetModel?: ResetPasswordModel) {
        this.map(resetModel);
    }

    map(resetModel?: ResetPasswordModel, reverse = false) {

        for (const property in resetModel) {
            const ourProperty = this.toggleFirstLetter(property, reverse);
            if (resetModel.hasOwnProperty(property) && this.hasOwnProperty(ourProperty)) {
                this[ourProperty] = resetModel[property];
            } else {
                console.log('Property not recognized on reset model:', property, ourProperty);
            }
        }
    }

    private toggleFirstLetter(string: string, upper = false) {
        const method = upper ? 'toUpperCase' : 'toLowerCase';
        return string.charAt(0)[method]() + string.slice(1);
    }
}