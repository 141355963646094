import { Injector } from '@angular/core';
import { Role } from './global.enum';

let appInjectorRef: Injector;

export const AppInjector = (injector?: Injector): Injector => {
  // tslint:disable-next-line:indent
  if (injector) { appInjectorRef = injector; } return appInjectorRef;
};

export const LocalStorageKeys = {
  'token': 'TokenDto'
};

export const DateFormat = 'dd/MM/YYYY';
export const DateFormatInput = 'DD/MM/YYYY'
export const BackendDateFormat = 'YYYY-MM-DDTHH:mm:ss';
export const YearFormat = 'YYYY'

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';


export const ValidationRules = {
  empty: [{ type: 'empty', prompt: '{name} trebuie să conțină o valoare' }],
  description: [{ type: 'minLength[8]', prompt: 'Te rugăm să întroduci o descriere' }],
  email: [{ type: 'minLength[3]', prompt: 'Te rugăm să întroduci o adresă de email validă' }, { type: 'email', prompt: 'Te rugăm să întroduci o adresă de email validă' }],
  common: [{ type: 'minLength[3]', prompt: '{name} trebuie să conțină cel putin 3 caractere' }],
  password: [{ type: 'minLength[6]', prompt: '{name} trebuie să conțină cel putin 6 caractere, o literă mare și un simbol.' }],
  dropdown: [{ type: 'empty', prompt: 'Te rugăm să selectezi o valoare din dropdown' }],
  cod: [{
    type: 'exactLength[4]', prompt: 'Te rugăm să întroduci un cod valid format din 4 cifre. ex. xxxx'
  }, { type: 'regExp[^[0-9][0-9][0-9][0-9]]', prompt: 'Te rugăm să întroduci un cod valid format din 4 cifre. ex. xxxx' }],
  phoneNumber: [{
    type: 'empty', prompt: 'Te rugăm să întroduci un număr de telefon valid'
  }],
  confirmationCode: [{
    type: 'exactLength[4]', prompt: 'Please enter a valid confirmation code'
  }],
  cnp: [{
    type: 'exactLength[13]', prompt: 'Te rugăm să întroduci un cnp valid'
  }],
  confirmPassword: [
    { type: 'empty', prompt: '{name} trebuie să conțină cel putin 6 caractere, o literă mare și un simbol' },
    { type: 'minLength[6]', prompt: 'Parola trebuie să conțină cel putin 6 caractere, o literă mare și un simbol' },
    { type: 'match[parola]', prompt: 'Confirmă parola nu este e identică cu parola' }
  ],
  file: [{ type: 'empty', prompt: 'Un document trebuie să fie încărcat' }]

};

export const AuthNames = {
  login: 'login',
  forgot: 'forgot',
  reset: 'reset',
  confirm: 'pymolsgyss',
  invite: 'qxeskjyjlf',
  create: 'vieykdnuev',
  prefix: 'auth',
  register: 'register',
  activate: 'confirm-account'
};

export const Routes = [
  {
    label: "Despre artiști",
    icon: 'users icon',
    children: [
      {
        label: "Cotizații",
        route: '/manage-debt',
        access: [Role.Admin, Role.Presedinte, Role.Contabilitate]
      },
      {
        label: "Lista artiști",
        route: '/manage-artist',
        access: [Role.Admin, Role.Presedinte, Role.Contabilitate]
      },    

      {
        label: "Lista utilizatori",
        route: '/manage-users',
        access: [Role.Admin, Role.Presedinte, Role.Contabilitate]

      },

      // {
      //   label: "Administreaza plati",
      //   route: '/manage-payment',
      //   access: [Role.Admin, Role.Secretariat]

      // }
    ]
  },
  {
    label: "Nomeclatoare",
    icon: 'folder icon',
    children: [
      {
        label: "Lista filiale",
        route: '/manage-branch',
        access: [Role.Admin, Role.Presedinte, Role.Contabilitate]

      },
      {
        label: "Lista specializări",
        route: '/manage-speciality',
        access: [Role.Admin, Role.Presedinte, Role.Contabilitate]

      },
      {
        label: "Lista statut-uri",
        route: '/manage-statute',
        access: [Role.Admin, Role.Presedinte, Role.Contabilitate]

      },
    ]
  },
  {
    label: "Despre mine",
    icon: 'user icon',
    children: [
      {
        label: "Profilul meu",
        route: '/profile',
        access: [Role.Admin, Role.Member, Role.Secretariat, Role.Contabilitate, Role.Presedinte]
      }
    ]
  }
];

export const enum ErrorsConst {
  internal = 'A apărut o preblemă, te rugăm să iei legătura cu un administrator',
  notFound = 'No resource was found at the requested address'
};

export const FILE_NAME_REGEX = /(?:\.([^.]+))?$/;


export const TermsAndConditions = ``;
export const PrivacyPolicy = ``;

export const Months = [
  { id: 1, name: 'Ianuarie' },
  { id: 2, name: 'Februarie' },
  { id: 3, name: 'Martie' },
  { id: 4, name: 'Aprilie' },
  { id: 5, name: 'Mai' },
  { id: 6, name: 'Iunie' },
  { id: 7, name: 'Iulie' },
  { id: 8, name: 'August' },
  { id: 9, name: 'Septembrie' },
  { id: 10, name: 'Octombrie' },
  { id: 11, name: 'Noiembrie' },
  { id: 10, name: 'Decembrie' }
];
