import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpecialityHistoryService extends BaseService {
  constructor() {
    super('specialityHistory');
  }

  getSpecialityHistory(artistId): Observable<Array<any>> {
    return this.http.get(this.url + '?ArtistId=' + artistId, { observe: 'response' }).pipe(map((response) => {
      return response.body as Array<any> || [];
    }));
  }
}
