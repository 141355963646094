<h4> Conectează-mă </h4>
<div class="ui form">
    <form class="ui large form fadeIn">
        <div class="ui mini error message"></div>
        <div class="ui mini red message" *ngIf="errors.length">
            <ul class="list">
                <li *ngFor="let error of errors" [innerHTML]="error"></li>
            </ul>
        </div>
        <div class="ui stacked">
            <div class="field">
                <div class="ui input">
                    <input type="text" name="email" placeholder="Adresa de e-mail" [(ngModel)]="email" required="required">
                </div>
            </div>
            <div class="field">
                <div class="ui input">
                    <input type="password" name="password" placeholder="Parola" [(ngModel)]="password" required="required">
                </div>
            </div>
            <button class="ui fluid black submit button login" [ngClass]="{'loading':loading}" (click)="logIn()" [disabled]="loading">Conectează-mă</button>
        </div>
    </form>
    <br />
    <small>
        <strong>
            <div class="ui centered grid">
                <div class="row">
                    <div class="sixteen wide computer sixteen wide mobile column centered aligned">
                        <small [routerLink]="authForgot" style="cursor:pointer;">
                            <strong>Recuperează parola</strong>
                        </small> /
    <small [routerLink]="register" style="cursor:pointer;">
                            <strong>Înregistrează-te</strong>
                        </small>
</div>
</div>
</div>
</strong>
</small>
</div>