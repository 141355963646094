import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '~shared/helpers/classes/base-component/base-component';
import { Column, Pagination } from '~shared/helpers/classes/pagination/pagination';
import { ArtistService } from '~shared/services/artist/artist.service';
import { ArtistModel, ExportArtistModel } from '~shared/services/artist/models';
import { BranchService } from '~shared/services/branch/branch.service';
import { BranchModel } from '~shared/services/branch/models';
import { SpecialityModel } from '~shared/services/speciality/models';
import { SpecialityService } from '~shared/services/speciality/speciality.service';
import { StatuteModel } from '~shared/services/statute/models';
import { StatuteService } from '~shared/services/statute/statute.service';
import { LifeStatusModel, QualityModel } from "~services/models";
import { ArtistFilterService } from "~services/filters/artist-filter/artist-filters.service";

const columns = [
  new Column('firstName', 'Prenume'),
  new Column('lastName', 'Nume'),
  new Column('previousLastName', 'Nume anterior'),
  new Column('quality', 'Calitate'),
  new Column('branch', 'Filială'),
  new Column('speciality', 'Specializare'),
  new Column('status', 'Statut'),
  new Column('phoneNumber', 'Număr de telefon'),
  new Column('email', 'Email')
];

@Component({
  selector: 'app-manage-artist',
  templateUrl: './manage-artist.component.html',
  styleUrls: ['./manage-artist.component.scss']
})
export class ManageArtistComponent extends BaseComponent<ArtistModel> implements OnDestroy, OnInit {
  @ViewChild('selectStatus', { static: true }) selectStatus: ElementRef;

  override columns = columns;
  specialityList = new Array<SpecialityModel>();
  branchList = new Array<BranchModel>();
  statuteList = new Array<StatuteModel>();
  aliveList = new Array<LifeStatusModel>();
  qualityList = new Array<QualityModel>();
  searchedArtistId: number;
  aliveOptionId: number = 0;
  qualityOptionId: number = 0;

  constructor(service: ArtistService,
              private specialityService: SpecialityService,
              private branchService: BranchService,
              private statuteService: StatuteService) {
    super(service, 'artistul');
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.getResources();
  }

  exportExcel() {
    const queryModel = new ExportArtistModel();
    queryModel.artistId = this.searchedArtistId;
    this.aliveOptionId ? queryModel.isAlive = this.aliveList[this.aliveOptionId].name : null;
    this.qualityOptionId ? queryModel.quality = this.qualityList[this.qualityOptionId].name : null;
    const reversedbranchList = this.branchList.slice().reverse();
    this.pagination.branchId ? queryModel.branch = reversedbranchList[this.pagination.branchId-1].name : null;
    const reversedstatuteList = this.statuteList.slice().reverse();
    this.pagination.statutId ? queryModel.statut = reversedstatuteList[this.pagination.statutId-1].name : null;
    const reversedSpecialityList = this.specialityList.slice().reverse();
    this.pagination.specialityId ? queryModel.speciality = reversedSpecialityList[this.pagination.specialityId-1].name : null;
    this.service.downloadFile(queryModel);
  }

  resetFilters() {
    this.pagination = new Pagination(() => { }, 1, 10000);
    this.aliveOptionId = 0;
    this.qualityOptionId = 0;
    this.getData();
  }

  private getResources() {
    this.specialityService.get(new Pagination(() => { }, 1, 10000))
      .subscribe((response) => {
        this.specialityList = response
      });
    this.branchService.get(new Pagination(() => { }, 1, 10000))
      .subscribe((response) => this.branchList = response);
    this.statuteService.get(new Pagination(() => { }, 1, 10000))
      .subscribe((response) => this.statuteList = response);
    this.aliveList = [
      { id: 0, name:'Toți', selected: false, value: null },
      { id: 1, name:'Vii', selected: false, value: 'true' },
      { id: 2, name:'Decedați', selected: false, value: 'false'}];
    this.qualityList = [
      { id: 0, name:'Toți', selected: false, value: null },
      { id: 1, name:'UAP', selected: false, value: 'UAP' },
      { id: 2, name:'FP', selected: false, value: 'FP'}];
  }

  onArtistSelection(id) {
    this.searchedArtistId = id;
    this.service.getSingle(id)
      .subscribe((response) => {
        this.data = [response];
        this.pagination.totalNumberOfItems = this.data.length;
      })
  }

  onFilterSelection() {
    this.pagination.isAlive = this.aliveList[this.aliveOptionId].value;
    this.pagination.quality = this.qualityList[this.qualityOptionId].value;
    console.log(this.pagination);
    this.getData();
  }

  reset() {
    this.searchedArtistId = 0;
    this.getData();
    const input: HTMLInputElement = document.querySelector('.table-filter-search input');
    if (input) {
      input.value = '';
    }
  }

}
