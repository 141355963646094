
import { AfterViewInit, Directive, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppInjector, handleSwaggerError, checkForm, setAlert, EMPTY_GUID } from "~shared/helpers";
import { AppNotificationService } from "~shared/services";

@Directive()
export class BaseAddComponent<T> implements OnInit, AfterViewInit {
    id: number = 0;
    model: T;

    loading: boolean;
    errors = [];
    error: string;

    redirectUrl: string;
    protected notification: AppNotificationService;

    constructor(protected service: any, TCreator: { new(): T; }, protected fields, protected entityName: string, protected route: ActivatedRoute, protected router: Router, private setNotification: boolean = false) {
        this.model = new TCreator();
        const injector = AppInjector();
        this.notification = injector.get(AppNotificationService);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            ($('.ui.form') as any).form({
                inline: true,
                fields: this.fields
            });
        }, 10);
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            if (params.id) this.id = Number(params.id);

            if (this.id) {
                this.loading = true;
                this.service.getSingle(this.id).subscribe((item) => {
                    this.model = item;
                    this.loading = false;
                }, handleSwaggerError.bind(this));
            }
        })
    }

    save() {

        if (!checkForm()) return;

        this.loading = true;

        this.service.save(this.model).subscribe((id: number) => {

            setAlert('success', this.entityName + " a fost " + ((this.model as any).id ? 'editat(ă)' : 'creat(ă)') + " cu succes");
            this.loading = false;
            this.router.navigateByUrl(this.redirectUrl);

        }, handleSwaggerError.bind(this, [{ setAlert: true }]));
    }
}