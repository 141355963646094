<div class="ui grid stackable">
  <div class="thirteen wide computer five wide tablet sixteen wide mobile column">
    <h3 class="component-heading">Profilul meu
    </h3>
  </div>

  <div class="three wide computer five wide tablet sixteen wide mobile column">
    <button class="ui mini blue right floated button" (click)="saveUser()" [ngClass]="{loading: loading}"
      [disabled]="loading">
      <i class="save icon"></i> <span>Salvează</span>
    </button>
  </div>
</div>
<div class="ui divider"></div>

<div class="ui grid">
  <div class="row">
    <div class="sixteen wide tablet ten wide computer column">
      <form class="ui account form">
        <div class="ui mini error message"></div>
        <div class="ui mini red message" *ngIf="errors.length">
          <ul class="list">
            <li *ngFor="let error of errors" [innerHTML]="error"></li>
          </ul>
        </div>
        <div class="field">
          <label>Avatar</label>
          <div class="ui small image">
            <img [src]="imageSrc || '../../../../assets/images/blank.png'">

            <label class="icon-delete-action" (click)="deleteAvatar()">
              <i class="close icon"></i>
            </label>

            <label class="icon-edit-action" (click)="avatar.click()">
              <i class="pencil icon"></i>
            </label>
            <input #avatar class="file-input" type="file" name="upload-image" accept=".jpg, .jpeg, .png"
              (change)="onFileChange($event)" />

          </div>

        </div>
        <div class="ui two fields">
          <div class="field required">
            <label>Prenume</label>
            <input type="text" name="firstName" placeholder="Prenume" [(ngModel)]="user.firstName">
          </div>

          <div class="field required">
            <label>Nume</label>
            <input type="text" name="lastName" placeholder="Prenume" [(ngModel)]="user.lastName">
          </div>
        </div>
        <div class="ui two fields">
          <div class="field">
            <label>Email</label>
            <div class="ui input disabled">
              <input type="email" name="email" placeholder="Email" [(ngModel)]="user.email">
            </div>
          </div>

        </div>
      </form>

      <h4 class="ui horizontal divider header">
        <i class="tag icon"></i> Datele mele ca artist
      </h4>
      <form class="ui artist form">

        <div class="three fields">
          <div class="field required">
            <label>Locul Nașterii</label>
            <input type="text" name="loculNasterii" placeholder="Locul Nașterii" [(ngModel)]="artist.birthPlace" />
          </div>
          <div class="field required">
            <label>CNP</label>
            <input type="number" name="ssn" placeholder="CNP" [(ngModel)]="artist.ssn" />
          </div>

          <div class="field required">
            <label>Adresa</label>
            <input type="text" name="adresa" placeholder="Adresa" [(ngModel)]="artist.address" />
          </div>
        </div>
        <div class="three fields">
          <div class="field required">
            <label>Cod</label>
            <input type="text" name="cod" placeholder="Cod" [(ngModel)]="artist.code" />
          </div>
          <div class="field">
            <label>Calitate</label>
            <input type="text" name="calitate" placeholder="Calitate" [(ngModel)]="artist.quality" />
          </div>
          <div class="field required">
            <label>Număr de telefon</label>
            <input type="text" name="numarDeTelefon" placeholder="Număr de telefon" [(ngModel)]="artist.phoneNumber" />
          </div>
        </div>
        <div class="three fields">
          <div class="field">
            <label>Filiala</label>
            <fm-dropdown [list]="branchList" [placeholder]="'Filiala'" [(optionId)]="artist.branchId">
            </fm-dropdown>
          </div>
          <div class="field">
            <label>Specializare</label>
            <fm-dropdown [list]="specialityList" [placeholder]="'Specializare'" [(optionId)]="artist.specialityId">
            </fm-dropdown>
          </div>
          <div class="field">
            <label>Statut</label>
            <fm-dropdown [list]="statuteList" [placeholder]="'Statut'" [(optionId)]="artist.statutId">
            </fm-dropdown>
          </div>
        </div>

        <div class="three fields">
          <div class="field required">
            <label>Data nașterii</label>
            <fm-datepicker [(model)]="artist.birthDate" [required]="true" placeholder="Data nașterii">
            </fm-datepicker>
          </div>

          <div class="field">
            <label>Data renunțării</label>
            <fm-datepicker [(model)]="artist.leaveDate" [required]="true" placeholder="Data renunțării">
            </fm-datepicker>
          </div>

          <div class="field">
            <label>Data decesului</label>
            <fm-datepicker [(model)]="artist.deathDate" [required]="true" placeholder="Data decesului">
            </fm-datepicker>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
