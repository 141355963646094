import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { handleSwaggerError, ValidationRules } from '~shared/helpers';
import { BaseAddComponent } from '~shared/helpers/classes/base-component/base-add-component';
import { ArtistDataService } from '~shared/services/artist-data/artist-data.service';
import { ArtistDataModel } from '~shared/services/artist-data/models';
import { ArtistService } from '~shared/services/artist/artist.service';
import { ArtistModel } from '~shared/services/artist/models';

const fields = { };

@Component({
  selector: 'app-add-artist-data',
  templateUrl: './add-artist-data.component.html',
  styleUrls: ['./add-artist-data.component.scss']
})
export class AddArtistDataComponent extends BaseAddComponent<ArtistDataModel> implements OnInit {
  artist: ArtistModel = null;

  override redirectUrl = '/manage-artist';

  constructor(service: ArtistDataService, private artistService: ArtistService, router: Router, route: ActivatedRoute) {
    super(service, ArtistDataModel, fields, 'Datele artistului', route, router, true);
  }

  override  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.id) this.id = Number(params.id);
      if (params.artistId) this.model.artistId = Number(params.artistId);
      this.getArtistData();

      if (this.id) {
        this.loading = true;
        this.service.getSingle(this.model.artistId).subscribe((item) => {
          this.model = item;
          this.loading = false;
        }, handleSwaggerError.bind(this));
      }
    })
  }

  private getArtistData() {
    this.artistService.getSingle(this.model.artistId).subscribe({
      next: (response) => {
        this.artist = response;
      },
      error: handleSwaggerError.bind(this)
    })
  }
}